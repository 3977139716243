import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch, } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Payment.scss';
import paymentimg from '../../assets/images/Payment by tapping card over payment terminal.png';

import razorpay from '../../assets/images/razorpay.png';
import flywire from '../../assets/images/flywire.png';
import Stripe from '../../assets/images/Stripe-Logo-blue.png';
import axios from '../../CustomAxios'
import { API_URL } from '../../config/Constants';
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom';
import Course from './Course';
import Instant from './Instant';
import Holiday from './Holiday';
// import Flywire from '@flywire/flywire-js';
import useRazorpay from "react-razorpay";
const PaymentPageDetails = ({ pageContents, loading }) => {
  const location = useLocation();
  const [Razorpay] = useRazorpay();
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const { user, isAuthenticated } = useSelector(state => state.auth);
  const [bookingData, setBookingData] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [BookingPrice, setBookingPrice] = useState(0);
  const [type, setType] = useState(false);
  const [notinvoiceGenerated, setNotInvoiceGenerated] = useState(false);
  const navigation = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handlePayment = async (params) => {
    const order ={id:1} //await createOrder(params); //  Create order on your backend
    
    const options = {
      key: "rzp_test_OmS0gbkvdImHcl", // Enter the Key ID generated from the Dashboard
      amount: BookingPrice * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: invoiceData?.currency,
      name: "Golftripz",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      // order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        console.log(response)
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
       
        var payloadData = {
          invoice_id: invoiceData?.invoice_number,
          invoice_date: invoiceData?.invoice_date,
          invoice_price: invoiceData?.invoice_price ,
          paid_amount: BookingPrice ,
          booking_reference_number: bookingData?.booking_ref_number,
          invoice_number: invoiceData?.invoice_number,
          total_price: BookingPrice ,
          transaction_price: BookingPrice ,
          payment_gateway_id: "4--RazorPay",
          payment_mode: "online",
          payment_response_json: response,
          user_id: user?.id,
          payment_currency: invoiceData?.currency,
          payment_remarks: "",
          transaction_reference_number: response?.razorpay_payment_id,
        }
        console.log(payloadData)
        axios(API_URL + '/add-payment-details', {
          method: 'POST',
          data: payloadData,
          headers: {
              'Content-Type': 'application/json'
          }
        })  .then(async ( responce) => {
            // console.log(responce)
            if(responce?.status){
              navigation('/GolfPaymentSuccess')
            }
           
        });
      },
      prefill: {
        name: "Piyush Garg",
        email: "rubesh@dss.com.sg",
        contact: "6374707609",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    }; const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
  
    rzp1.open();
  };


  const SuccessPage = () => {
    setAnchorElNav(null);
    navigation('/GolfPaymentSuccess')
  };
  const decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  };
  useEffect(() => {
    var data = location.search;
    
    if (data !== "") {
      var encryptedid = data.substr(1)
      var decrypted_string = decrypt("salt", encryptedid); // -> Hello
      console.log('decrypted_string', decrypted_string);
      // alert(decrypted_string)
      var bookingid = decrypted_string.split('&');
      bookingid = bookingid[0].split('=');
      getBookingDetails(bookingid[1])
    }

  }, [])
 



  // const [startDate, setStartDate] = useState(new Date());
  // const [selectedTab, setSelectedTab] = useState('credit');
  // const [formValues, setFormValues] = useState({
  //   name: '',
  //   cardNumber: '',
  //   expiryDate: '',
  //   cvv: '',
  //   details: '',
  //   couponCode: ''
  // });


  // const [formErrors, setFormErrors] = useState({});
  // const validateForm = () => {
  //   let errors = {};
  //   if ((selectedTab === 'credit' || selectedTab === 'debit') && !formValues.name) {
  //     errors.name = 'Name on the card is required';
  //   }
  //   if ((selectedTab === 'credit' || selectedTab === 'debit') && !formValues.cardNumber) {
  //     errors.cardNumber = 'Card number is required';
  //   }
  //   if ((selectedTab === 'credit' || selectedTab === 'debit') && !formValues.expiryDate) {
  //     errors.expiryDate = 'Expiry date is required';
  //   }
  //   if ((selectedTab === 'credit' || selectedTab === 'debit') && !formValues.cvv) {
  //     errors.cvv = 'CVV number is required';
  //   }
  //   if (selectedTab !== 'credit' && selectedTab !== 'debit' && !formValues.details) {
  //     errors.details = `Details for ${selectedTab} are required`;
  //   }
  //   if (selectedTab !== 'credit' && selectedTab !== 'debit' && !formValues.couponCode) {
  //     errors.couponCode = `Details for ${selectedTab} are required`;
  //   }
  //   return errors;
  // };





  const [selectedPayment, setSelectedPayment] = useState('Stripe');

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  const [isPopupVisible, setPopupVisible] = useState(false);
  const popupRef = useRef(null);

  const handleButtonClick = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setPaymentLoading(false)
  };

  const handleClickOutside = (event) => {
    // Prevent closing popup if clicking outside
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      return;
    }
  };

  const stripe = useStripe();
  const elements = useElements();

  const confirmPayment = async () => {
    // alert(Math.round("343.50"))
    // return;
    
    let data = {
      amount: parseFloat(BookingPrice),
      currency: "USD",
      email: user?.email
    }
    
    setPaymentLoading(true)
    axios(API_URL + '/payment', {
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(async ({ data }) => {
      let customerId = data?.data?.customer
      const { paymentIntent, error } = await stripe.confirmCardPayment(data.data?.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: user?.name
          },
        },
        setup_future_usage: 'off_session'
      });

      if (error) {
        setPaymentLoading(false);
        toast.error(error.message);
      } else {
        if (paymentIntent.status === "succeeded") {
          // SEND THE API REQUET WITH DETAILS
         
          setPopupVisible(false);
          var payloadData = {
            invoice_id: invoiceData?.invoice_number,
            invoice_date: invoiceData?.invoice_date,
            invoice_price: invoiceData?.invoice_price / 100,
            paid_amount: paymentIntent?.amount / 100,
            booking_reference_number: bookingData?.booking_ref_number,
            invoice_number: invoiceData?.invoice_number,
            total_price: paymentIntent?.amount / 100,
            transaction_price: paymentIntent?.amount / 100,
            payment_gateway_id: "2--strip",
            payment_mode: "online",
            payment_response_json: paymentIntent,
            user_id: user?.id,
            payment_currency: paymentIntent?.currency,
            payment_remarks: "",
            transaction_reference_number: paymentIntent?.id,
          }
          console.log(payloadData)
          axios(API_URL + '/add-payment-details', {
            method: 'POST',
            data: payloadData,
            headers: {
                'Content-Type': 'application/json'
            }
          })  .then(async ( responce) => {
              // console.log(responce)
              if(responce?.status){
                navigation('/GolfPaymentSuccess')
              }
             
          });
        }
      }

    }).catch(err => {
      setPaymentLoading(false);
    })

  }

  const getBookingDetails = async (id) => {

    await axios(API_URL + '/get-reference-booking-details/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function (response) {

      const { data } = response.data;
      if (data) {
        console.log("dataaaa", data)
        if (data?.instant_package) {
          setType("instant")
          var invoiceData = {
            invoice_id: bookingData?.instant_package?.payment_details?.invoice_number,
            invoice_date: bookingData?.instant_package?.payment_details?.invoice_date,
            invoice_price: bookingData?.instant_package?.payment_details?.invoice_price,
            currency: bookingData?.instant_package?.payment_details?.currency
          }
        } else if (data?.golf_course) {
          setType("course")
          var invoiceData = {
            invoice_id: bookingData?.golf_course?.payment_details?.invoice_number,
            invoice_date: bookingData?.golf_course?.payment_details?.invoice_date,
            invoice_price: bookingData?.golf_course?.payment_details?.invoice_price,
            currency: bookingData?.golf_course?.payment_details?.currency
          }
        } else if (data?.golf_holiday) {
          setType("holiday")
          var invoiceData = {
            invoice_id: bookingData?.golf_holiday?.payment_details?.invoice_number,
            invoice_date: bookingData?.golf_holiday?.payment_details?.invoice_date,
            invoice_price: bookingData?.golf_holiday?.payment_details?.invoice_price,
            currency: bookingData?.golf_holiday?.payment_details?.currency
          }
        }
         // alert(data?.payment_details.length)
         if(data?.payment_details.length <= 0){
          // alert("Cannot make payment until invoice generated");
          setNotInvoiceGenerated(true)
        }
        var invoiceDetails=data?.payment_details.filter(invoice=>invoice?.booking_reference_number ===id)
        invoiceDetails=Object.assign({}, invoiceDetails[0]);
        console.log(invoiceDetails)
        setInvoiceData(invoiceDetails)
        setBookingData(data)
        setBookingPrice(invoiceDetails?.invoice_price)
      }

    })
  }
  return (
    <div className='listitem'>
      <div className='paymentpage-container'>
        <h2 className='payment-pgnew-title'>Payment Details</h2>
        <Grid container spacing={2} className='paymentpage-drow'>
          <Grid xs={6} className='max-width-full'>
            <h2 className='payment-tripsum-tit'>Summary</h2>

            {type === 'course' ? (
              <Course datas={bookingData} />
            ) : type === 'instant' ? (
              <Instant datas={bookingData} />
            ) : (
              <Holiday datas={bookingData} />
            )}

          </Grid>
          <Grid xs={5} className='payment-tripsummary-container max-width-full'>
            <div className='payment-tripsum-rgt'>
              <div className='pay-summary-fulltotal'>
                <h5 className='payment-tripsum-tit'>Payment Summary</h5>
                <div>
                      <p>Booking Ref Number</p>
                      <h5>{bookingData?.booking_ref_number}</h5>
                </div>
                <div>
                      <p>Invoice Number</p>
                      <h5>{notinvoiceGenerated ?"Invoice not Generated for this booking": invoiceData?.invoice_number}</h5>
                </div>
                {type === 'course' ? (
                  <>
                    {bookingData?.golf_course?.booking_details?.map((coures) => (
                      <>
                        <div>
                          <p>{coures?.golf_course_name}</p>
                          <h5>{invoiceData?.currency}  {coures?.total_price?.toFixed(2)}</h5>
                        </div>
                      </>
                    ))
                    }


                    <div className='pay-trip-total'>
                      <p>Total Price</p>
                      <h5 className='pay-price'>{invoiceData?.currency} {bookingData?.total_price?.toFixed(2)}</h5>
                    </div>
                  </>

                ) : type === 'instant' ? (
                  <>
                    {/* <div>
                      <p>Stay at Melbourne 5 Star Hotel</p>
                      <h5>$120</h5>
                    </div>
                    <div>
                      <p>Platform Fee</p>
                      <h5 className='pay-price'>$120</h5>
                    </div>
                    <div className='pay-trip-dis'>
                      <p>Discount</p>
                      <h5 className='pay-price'>$120</h5>
                    </div> */}
                    <div className='pay-trip-total'>
                      <p>Total Price</p>
                      <h5 className='pay-price'>{invoiceData?.currency} {bookingData?.total_price?.toFixed(2)}</h5>
                    </div>
                  </>
                ) : type === 'holiday' ?(
                  <>
                    {/* <div>
                      <p>Stay at Melbourne 5 Star Hotel</p>
                      <h5>$120</h5>
                    </div>
                    <div>
                      <p>Platform Fee</p>
                      <h5 className='pay-price'>$120</h5>
                    </div>
                    <div className='pay-trip-dis'>
                      <p>Discount</p>
                      <h5 className='pay-price'>$120</h5>
                    </div> */}
                    <div className='pay-trip-total'>
                      <p>Total Price</p>
                      <h5 className='pay-price'>{invoiceData?.currency} {bookingData?.total_price?.toFixed(2)}</h5>
                    </div>
                  </>
                ):''}

              </div>
              <div>
                <h5 className='payment-tripsum-tit'>Choose Payment Type</h5>
                <div className="payment-selection">

                  <div className={`payment-option ${selectedPayment === 'Stripe' ? 'selected' : ''}`}>
                    <input
                      type="radio"
                      name="payment"
                      value="Stripe"
                      id="stripe"
                      checked={selectedPayment === 'Stripe'}
                      onChange={handlePaymentChange}
                    />
                    <label htmlFor="stripe" className="payment-label">
                      <div className="payment-logo">
                        <img src={Stripe} alt="Stripe" />
                      </div>
                    </label>
                  </div>

                  <div className={`payment-option ${selectedPayment === 'razorpay' ? 'selected' : ''}`}>
                    <input
                      type="radio"
                      name="payment"
                      value="razorpay"
                      id="razorpay"
                      checked={selectedPayment === 'razorpay'}
                      onChange={handlePaymentChange}
                    />
                    <label htmlFor="razorpay" className="payment-label">
                      <div className="payment-logo">
                        <img src={razorpay} alt="Razorpay" />
                      </div>
                    </label>
                  </div>

                  <div className={`payment-option ${selectedPayment === 'flywire' ? 'selected' : ''}`}>
                    <input
                      type="radio"
                      name="payment"
                      value="flywire"
                      id="flywire"
                      checked={selectedPayment === 'flywire'}
                      onChange={handlePaymentChange}
                    />
                    <label htmlFor="flywire" className="payment-label">
                      <div className="payment-logo">
                        <img src={flywire} alt="Flywire" />
                      </div>
                    </label>
                  </div>

                </div>
              </div>
       
              {isPopupVisible && (
                <div
                  style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                  }}
                  onClick={handleClickOutside}
                >
                  <div
                    ref={popupRef}
                    style={{
                      width: '25%',
                      backgroundColor: 'white',
                      position:"relative",
                      padding: '40px 20px',
                      borderRadius:"20px",
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    }}
                  >
                    <div
                    style={{
                      display:"flex",
                      alignItems:"center",
                      justifyContent:'center',
                    }}>
                    <div className="payment-popuplogo">
                        <img src={Stripe} alt="Stripe" />
                    </div>
                  </div>
                           <button
                        onClick={handleClosePopup}
                        type="button"
                        style={{
                          width:"20px",
                          height:"20px",
                          top:"15px",
                          right:"15px",
                          backgroundColor: '#f44336',
                          color: 'white',
                          border: 'none',
                          borderRadius: '50%',
                          cursor: 'pointer',
                          position:"absolute",
                          display:"flex",
                          alignItems:"center",
                          justifyContent:'center',
                        }}
                      >
                        X
                      </button>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '2px',
                      }}
                    >
                      <CardElement
                        className="card"
                        options={{
                          hidePostalCode: true,
                          style: {
                            base: {
                              backgroundColor: 'white',
                            },
                          },
                        }}
                      />
                    </div>
                    <Grid className='max-width-full'>
                      <div className="form-group">
                        <input
                          name="full_name"
                          placeholder="Enter your full name here"
                          required
                        />
                      </div>
                    </Grid>
                    <Grid className='max-width-full'>
                      <div className="form-group">
                        <input
                          name="email"
                          placeholder="Enter Email here"
                          required
                        />
                      </div>
                    </Grid>
                    <div className='paypopup-btns'>
                      <button
                        onClick={isPaymentLoading ? null : confirmPayment}
                        type="submit"
                        style={{
                          marginTop: '10px',
                          padding: '10px 20px',
                          background: 'linear-gradient(94deg, #00acbf -35.57%, #9fd650 119.86%)',
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        {isPaymentLoading ? "Loading..." : "Proceed to Pay"}
                      </button>
                    </div>

                  </div>
                </div>
              )}
              {selectedPayment ==="Stripe" ?
              <button onClick={handleButtonClick} type="submit" disabled ={notinvoiceGenerated} className="btn-payment"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                <path d="M23 8V18C23 19.1 22.1 20 21 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H21V8C21 7.45 21.45 7 22 7C22.55 7 23 7.45 23 8ZM4 16C2.34 16 1 14.66 1 13V7C1 5.34 2.34 4 4 4H16C17.66 4 19 5.34 19 7V14C19 15.1 18.1 16 17 16H4ZM7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7C8.34 7 7 8.34 7 10Z" fill="white" />
              </svg></span>Proceed to pay</button> : 
              <button onClick={handlePayment} type="submit" disabled ={notinvoiceGenerated} className="btn-payment"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
              <path d="M23 8V18C23 19.1 22.1 20 21 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H21V8C21 7.45 21.45 7 22 7C22.55 7 23 7.45 23 8ZM4 16C2.34 16 1 14.66 1 13V7C1 5.34 2.34 4 4 4H16C17.66 4 19 5.34 19 7V14C19 15.1 18.1 16 17 16H4ZM7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7C8.34 7 7 8.34 7 10Z" fill="white" />
            </svg></span>Proceed to pay</button>
            }
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PaymentPageDetails;
