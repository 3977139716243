import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import insgolf from '../../assets/images/insgolf.png'
import mapin from '../../assets/images/Rectangle 13404.png'
import inslodg from '../../assets/images/inslodg.png'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';
import DatePicker from 'react-datepicker';
import GolfCourses from './GolfCourses';
import CoursePopup from './CoursePopup';
import Hotels from './Hotels';
import HotelPopup from './HotelPopup';
import { redirect, useNavigate } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faChild, faUsers } from '@fortawesome/free-solid-svg-icons';
import { BOOKING_INPUT, BOOKING_TYPE, SELECTED_PACKAGES, SET_SELECTED_PACKAGE } from '../../Redux/constants/bookingConstants'
import axios from '../../CustomAxios'
import { API_URL } from '../../config/Constants';
import { InstantPackageBooking } from '../../Redux/actions/bookingActions'
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import Noimage from "../../assets/images/no-img.png";

const InstantPackageDetails = ({ count }) => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { selectedPackage, InstantPackagesInputs, instantBookingSuccess } = useSelector(state => state.booking)
    const [packageDetail, setPackageDetail] = useState(selectedPackage)

    const { userCurrencyCode, currencyRate , dbCountry } = useSelector(state => state.currency);
    const [currencyCode, setCurrencyCode] = useState('')
    const [currencyRateList, setCurrencyRateList] = useState([]);
    const [nationalities , setNationalities] = useState([]);

    useEffect(() => {
        if (userCurrencyCode) {
            setCurrencyCode(userCurrencyCode)
        }
    }, [userCurrencyCode]);

    useEffect(() => {
        if (currencyRate) {
            setCurrencyRateList(currencyRate)
        }
    }, [currencyRate]);

    useEffect(() => {
        if(dbCountry){
            setNationalities(dbCountry)
    }
      }, [dbCountry]);

    const fnctionConvertingPriceWithCurrency = (price, currency) => {
        const currencyRate = currencyRateList[currency];
        const convertedPrice = price / currencyRate
        return convertedPrice.toFixed(2);
    }

    useEffect(() => {
        loadCaptchaEnginge(6);
        if (packageDetail === null || packageDetail === undefined) {
            navigation(`/InstantPackages`);
        } else {
            console.log("selectedPackage", selectedPackage)


        }
    }, [packageDetail, selectedPackage])

    const { user, isAuthenticated } = useSelector(state => state.auth);
    const [selectedhotels, setSelectedHotels] = useState([])
    const [selectedcourses, setSelectedCourses] = useState([])
    const [courseDatas, setCourseDatas] = useState([])
    const [hotelDatas, setHotelDatas] = useState([])

    useEffect(() => {

        if (packageDetail === null || packageDetail === undefined) {
            navigation(`/InstantPackages`);
        } else {

            setSelectedHotels(packageDetail?.golf_hotel);
            var courseslist = packageDetail?.golf_course;

            var date = moment(InstantPackagesInputs[0]?.date).format('DD-MM-YYYY');
            // alert(new Date(date)) 
            const courses = courseslist.map(v => ({ ...v, coursedate: new Date(date), selected: false }))
            setSelectedCourses(courses);

            console.log("packageDetailssssssss", packageDetail)
        }

    }, [packageDetail, selectedPackage])


    const [name, setName] = useState();
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [cus_nationality, setCusnationality] = useState('');

    useEffect(() => {
        getHotelPrice()
        if (user) {
            setName(user?.name)
            setEmail(user?.email)
            setPhone(user?.phone)
        }
        if (instantBookingSuccess) {
            //localStorage.removeItem("booking");
            navigation('/SuccessPage');

        }
    }, [instantBookingSuccess])


    // ------------tab change--------
    const [activeTab, setActiveTab] = useState('trip-plan');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    // --------------review box------------
    const [activeBox, setActiveBox] = useState(null);

    const handleBoxClick = (index) => {
        setActiveBox(index);
    };
    const [golfCoures, setGolfCoures] = useState([]);
    const [showPopup2, setShowPopup2] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);
    const openPopup2 = () => {
        setShowPopup2(true)
    }
    const openPopup1 = () => {
        setShowPopup1(true)
    }
    const closePopup2 = () => {
        setShowPopup2(false)
    }
    const closePopup1 = () => {
        setShowPopup1(false)
    }

    useEffect(() => {

        handleCourseInputs()
        handleHotelInputs()
    }, [selectedcourses, selectedhotels])

    const handleHotelInputs = async () => {
        var hotelInput = [];

        selectedhotels?.map(hotel => {
            // console.log("hh",hotel)
            var hotelData = {
                pack_id: packageDetail?.id,
                hotel_id: hotel?.id,
                hotel_name: hotel?.hotel_name,
                hotel_description: hotel?.hotel_description,
                hotel_image: hotel?.thumbnail_images,
                check_in_date: new Date(),
                check_out_date: new Date(),
                child: 0,
                adults: 1,
                no_of_room: 1,
                room_category_name: 0,
                room_price: hotel?.room[0]?.roomrates?.weekdays_cost_price,
                total_price_perday: hotel?.room[0]?.roomrates?.weekdays_cost_price,
                convenience_fee: 0,
                tax: 3,
                day: 1,
                room_id: [hotel?.room[0]?.id],
                total_price: hotel?.room[0]?.roomrates?.weekdays_cost_price,
                currency: hotel?.room[0]?.roomrates?.currency?.currencyCode,
            }
            hotelInput.push(hotelData)
        })
        setHotelDatas(hotelInput)
    }

    const [deftimelot, setDefaultTimeSlot] = useState([]);
    const [timeslotprice, setTimeSlotPrice] = useState([]);

    const getInitTimeslot = async (timeslots,price) => {
        console.log("timeslot",timeslots,price)
      //  alert(price)
        deftimelot.push(timeslots)
        timeslotprice.push(price)
        setTimeSlotPrice(timeslotprice)
        setDefaultTimeSlot(deftimelot)
    }


    function handleCourseInputs() {
       
        var courseInput = [];
        const uniquePrices = timeslotprice.filter((val,id,array) => array.indexOf(val) == id);
        selectedcourses?.map((course, index) => {
           
            var courseData = {
                pack_id: packageDetail?.id,
                golf_course_id: course?.id,
                golf_course_name: course?.golf_course_name,
                golf_course_image: course?.thumb_nail_images,
                description: course?.description,
                tee_time: deftimelot[index],
                no_of_golfers: 1,
                product_id: [],
                price_of_golfers: parseInt(uniquePrices[index]),
                currency: course?.rate_sheets_relation?.currency?.currencyCode,
                convenience_fee: 3,
                tax: 0,
                total_price: parseInt(uniquePrices[index]),
                day: 0,
                date: moment(InstantPackagesInputs[0]?.date, 'DD-MM-YYYY').format("YYYY-MM-DD"),
                selected: false,
            }
            courseInput.push(courseData)
        })

        setCourseDatas(courseInput)

    }
    const getGolferCount = () => {
        // return 10;
        var count = 0;
        courseDatas?.map(course => {
            count += parseInt(course?.no_of_golfers)
        })
        return count;
    }

    const getTotalPrice = () => {
       
        var courseprice = 0;
        var totalprice = 0;
        courseDatas?.map(course => {
            courseprice += parseFloat(fnctionConvertingPriceWithCurrency(parseInt(course?.price_of_golfers * course?.no_of_golfers), course?.currency))
        })
        var hotelprice = 0;

        hotelDatas?.map(hotel => {
            hotelprice += parseFloat(fnctionConvertingPriceWithCurrency(parseInt(hotel?.total_price), hotel?.currency))
        })
        return totalprice = (courseprice + hotelprice).toFixed(2);
    }



    const removeCourse = (id) => {
        var course = selectedcourses.filter(item => item.id !== id);
        setSelectedCourses(course)
    }

    const [hotelPrice, setHotelPrice] = useState(0);
    const [coureprice, setCoursePrice] = useState(0);
    useEffect(() => {
        getHotelPrice()
        getCoursePrice()
    }, [hotelDatas])

    const getHotelPrice = () => {
        var price = 0;
        hotelDatas?.map(hotel => {
            price += parseFloat(fnctionConvertingPriceWithCurrency(parseInt(hotel?.total_price), hotel?.currency))
            // price+= parseFloat(parseInt(hotel?.total_price))
        })
        setHotelPrice(price.toFixed(2))
    }

    const getCoursePrice = () => {
        var c_price = 0;
        courseDatas?.map(course => {
            c_price += parseFloat(fnctionConvertingPriceWithCurrency(parseInt(course?.total_price), course?.currency))
            // c_price+=parseFloat(parseInt(course?.total_price))
        })
        setCoursePrice(c_price.toFixed(2))
    }


    //course booking inputs
    const handleBooking = async (name, value, id) => {

        if (name === "coureGolfers") {
            var data = courseDatas.map((item, idx) =>
                item.golf_course_id === id ? { ...item, no_of_golfers: parseInt(value) } : item
            )
             setCourseDatas(data);
            //  await  console.log(courseDatas)
        }
        else if (name === "timeslot") {
            var data = courseDatas.map((item, idx) =>
                item.golf_course_id === id ? { ...item, tee_time: value } : item
            )
            setCourseDatas(data);
            var numbers = value.replace(currencyCode, '').replace('<span>', '');
            var price = numbers.substring(numbers.indexOf("(") + 1, numbers.indexOf(")"))
            var datanew = courseDatas.map((item, idx) =>
                item.golf_course_id === id ? { ...item, price_of_golfers: parseInt(price) } : item
            )
            setCourseDatas(datanew);
        }
        else if (name === "selected") {
            console.log("asdfasdf")
            var data = courseDatas.map((item, idx) =>
                item.golf_course_id === id ? { ...item, selected: value } : item
            )
            setCourseDatas(data);
        }
        else {
            var selected_date = moment(value, 'DD-MM-YYYY').format("YYYY-MM-DD")
            var data = courseDatas.map((item, idx) =>
                item.golf_course_id === id ? { ...item, date: selected_date } : item
            )
            setCourseDatas(data);

        }
    }

    const handlehotelBooking = async (name, value, id) => {
        if (name === "no_of_child") {
            var data = hotelDatas.map((item, idx) =>
                item.hotel_id === id ? { ...item, child: parseInt(value) } : item
            )
            await setHotelDatas(data);
        }
        else if (name === "no_of_adult") {
            var data = hotelDatas.map((item, idx) =>
                item.hotel_id === id ? { ...item, adults: parseInt(value) } : item
            )
            setHotelDatas(data);
        } else if (name === "checkin") {
            var checkindate = moment(value, 'DD-MM-YYYY').format("YYYY-MM-DD")
            var data = hotelDatas.map((item, idx) =>
                item.hotel_id === id ? { ...item, check_in_date: checkindate } : item
            )
            console.log(data)
            setHotelDatas(data);
        } else if (name === "checkout") {
            var checkoutdate = moment(value, 'DD-MM-YYYY').format("YYYY-MM-DD")
            var data = hotelDatas.map((item, idx) =>
                item.hotel_id === id ? { ...item, check_out_date: checkoutdate } : item
            )
            setHotelDatas(data);
        } else if (name === "room_type") {

            var data = hotelDatas.map((item, idx) =>
                item.hotel_id === id ? { ...item, room_id: [value] } : item
            )
            setHotelDatas(data);
        }

    }

    useEffect(() => {
        // Add event listener to handle body scroll when any popup is open
        if (showPopup1) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        // Clean up function to remove event listener
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showPopup1]);

    const removeHotel = (id) => {
        if (selectedhotels?.length <= 1) {
            alert("Hotels must be more than one");
        } else {
            var hotels = selectedhotels.filter(item => item.id !== id);
            setSelectedHotels(hotels)
        }
    }

    const [hotelreplace, setHotelReplace] = useState(false);
    const [coursereplace, setCourseReplace] = useState(false);
    const [replacehotelid, setReplaceHotelId] = useState();
    const [replacecourseid, setReplaceCourseId] = useState();

    const addreplacecoure = async (courseInfos) => {
        if (coursereplace) {
            var courses = selectedcourses.filter(item => item.id != replacecourseid);
            courses.push(courseInfos)
            setSelectedCourses(courses)
        } else {
            await setSelectedCourses(oldArray => [...oldArray, courseInfos])
        }
        setShowPopup2(false)
    }

    const addreplacehotel = async (hotelInfos) => {
        if (hotelreplace) {
            var hotels = selectedhotels.filter(item => item.id != replacehotelid);
            hotels.push(hotelInfos)
            setSelectedHotels(hotels)
            // selectedhotels
        } else {
            await setSelectedHotels(oldArray => [...oldArray, hotelInfos])
        }
        setShowPopup1(false)
    }


    const replacehotel = (id) => {
        setReplaceHotelId(id)
        setHotelReplace(true)
        setShowPopup1(true)
    }
    const replacecourse = (id) => {
        setReplaceCourseId(id)
        setCourseReplace(true)
        setShowPopup2(true)
    }
    const [emailBooking, setemailBooking] = useState(false);
    const [mesagingApp, setmesagingApp] = useState(false);

    const IsEmailChecked = () => {
        setemailBooking(!emailBooking)
    }

    const IsMessaging = () => {
        setmesagingApp(!mesagingApp)
    }

    const [captcha, setCaptcha] = useState("");

    const HandleSubmit = async (e) => {

        e.preventDefault();
        // const uniqueNames = timeslotprice.filter((val,id,array) => array.indexOf(val) == id);
        // console.log("timeslotprice", uniqueNames) 
        // console.log("courseDatas", courseDatas) 
        // return
        if (user) {
            if (name === "" || email === "" || phone === "" || nationalities === "") {

                alert("Confirm All Fields")
                return
            }
            if (validateCaptcha(captcha) == false) {
                alert('Captcha Does Not Match');
            } else {
                // const selectedCourseData =  courseDatas.filter(course => course.selected === true);
                var inputData = {
                    pack_id: packageDetail?.id,
                    customer_id: user?.id,
                    pack_name: packageDetail?.value,
                    total_price: getTotalPrice(),
                    booking_date: InstantPackagesInputs[0]?.date,
                    customer_name: name,
                    customer_email: email,
                    customer_phone: phone,
                    customer_nationality: cus_nationality,
                    playing_days: InstantPackagesInputs,
                    emailBooking: emailBooking,
                    mesagingApp: mesagingApp,
                    currency: currencyCode,
                    selected_course: courseDatas,
                    selected_hotel: hotelDatas,
                }
                console.log(inputData)
                await dispatch(InstantPackageBooking(inputData));
            }
        } else {
            alert("Please Login")
        }
    };


    return (
        <div className='golf-holiday-details listitem' id='instant-package-details'>
            <div className="detail-img-container">
                <div className="img-bg-box-lg">
                    <div className="img-wrapper">
                        <div className="img-cont-head">
                            <h4>{packageDetail?.golf_hotel[0]?.hotel_name}</h4>
                            <div class="listitem-duration">
                                <p class="listitem-country-sec"><span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"><path d="M20.6191 8.45C19.5691 3.83 15.5391 1.75 11.9991 1.75C11.9991 1.75 11.9991 1.75 11.9891 1.75C8.45912 1.75 4.41912 3.82 3.36912 8.44C2.19912 13.6 5.35912 17.97 8.21912 20.72C9.27912 21.74 10.6391 22.25 11.9991 22.25C13.3591 22.25 14.7191 21.74 15.7691 20.72C18.6291 17.97 21.7891 13.61 20.6191 8.45ZM11.9991 13.46C10.2591 13.46 8.84912 12.05 8.84912 10.31C8.84912 8.57 10.2591 7.16 11.9991 7.16C13.7391 7.16 15.1491 8.57 15.1491 10.31C15.1491 12.05 13.7391 13.46 11.9991 13.46Z" fill="#fff"></path></svg></span>{packageDetail?.golf_hotel[0]?.address}</p><p><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"><path d="M12.0001 4.65002C7.22008 4.65002 3.33008 8.54002 3.33008 13.32C3.33008 18.1 7.22008 22 12.0001 22C16.7801 22 20.6701 18.11 20.6701 13.33C20.6701 8.55002 16.7801 4.65002 12.0001 4.65002ZM12.7501 13C12.7501 13.41 12.4101 13.75 12.0001 13.75C11.5901 13.75 11.2501 13.41 11.2501 13V8.00002C11.2501 7.59002 11.5901 7.25002 12.0001 7.25002C12.4101 7.25002 12.7501 7.59002 12.7501 8.00002V13Z" fill="#fff"></path><path d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z" fill="#fff"></path></svg></span>1 hr 30 mins from</p></div>
                        </div>
                        <div className="details-img-box">
                            <img onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} src={packageDetail?.cover_image} alt="image" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='details-tab-content'>
                <div className='container-d-page'>
                    <Grid container spacing={2} className='container-d-row'>
                        <Grid xs={8.8}>
                            <div className="detailsinfo-con">
                                <h4 className="details-cont-tabhead">What’s Included | {InstantPackagesInputs?.length}Nights/{selectedcourses?.length}Rounds <span>{InstantPackagesInputs && moment(InstantPackagesInputs[0]?.date, 'DD-MM-YYYY').format('MMM Do')} - {InstantPackagesInputs && moment(InstantPackagesInputs[InstantPackagesInputs?.length - 1]?.date, 'DD-MM-YYYY').format('MMM Do')}</span></h4>
                                <div className='instant-golfcourse-bx'>
                                    <div className='instant-gco-head'>
                                        <div className='inshead-df'>
                                            <div className='inslodg-img'>
                                                <img onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} src={inslodg} alt="image" />
                                            </div>
                                            <p>Lodging</p>
                                        </div>
                                        <button onClick={openPopup1}><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M13.3337 8.66602H8.66699V13.3327H7.33366V8.66602H2.66699V7.33268H7.33366V2.66602H8.66699V7.33268H13.3337V8.66602Z" fill="#598B28" />
                                        </svg></span>Add Lodging</button>
                                    </div>
                                    {selectedhotels?.map((hotel, index) => (
                                        <Hotels hotel={hotel} handlehotelbookingdata={handlehotelBooking} removegolfhotel={removeHotel} hotelchange={replacehotel} />
                                    ))}
                                    {/* POPUP */}
                                    {showPopup1 && (
                                        <HotelPopup addreplacehotel={addreplacehotel} closePopup1={closePopup1} />
                                    )}
                                </div>
                            </div>
                            <div className="detailsinfo-con">
                                <div className='instant-golfcourse-bx'>
                                    <div className='instant-gco-head'>
                                        <div className='inshead-df'>
                                            <div className='inslodg-img'>
                                                <img onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} src={insgolf} alt="image" />
                                            </div>
                                            <p>Golf Courses</p>
                                        </div>
                                        <button onClick={openPopup2}><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M13.3337 8.66602H8.66699V13.3327H7.33366V8.66602H2.66699V7.33268H7.33366V2.66602H8.66699V7.33268H13.3337V8.66602Z" fill="#598B28" />
                                        </svg></span>Add Round</button>
                                    </div>
                                    {selectedcourses?.map((course, index) => (

                                        <GolfCourses course={course} indexkey={index} handlebookingdata={handleBooking} removegolfcourse={removeCourse} timeslot={getInitTimeslot} coursechange={replacecourse} />
                                    ))}
                                    {/* POPUP */}
                                    {showPopup2 && (
                                        <CoursePopup addreplacecoure={addreplacecoure} closePopup2={closePopup2} />
                                    )}

                                </div>
                            </div>
                            <div className="detailsinfo-con instant-para-box">
                                <h4 className="details-cont-tabhead">Westin Hilton Head Island</h4>
                                <div className='inst-para-list'>
                                    <p>The 9 Aces Golf Greens & Academy is a 2-hour drive from Mumbai, set in the beautiful Western Ghats. With a unique 9-hole layout and splendid views of the hills, it is an ideal option for a short getaway for the golf enthusiasts.</p>
                                    <p>Each of the holes bears a name that is key to scoring well. The hilly terrain with water on most of the holes and the slopes on fairways, make up the challenges at the 9 Aces golf course. The course also has an island green.</p>
                                    <p>Stop at the ‘19th’, a small food & beverage venue, shaped like a hut, on the course. It provides for a great meal and a relaxing environment along with beautiful views of the course.</p>
                                    <p>Golf learning lessons and game improvement tactics are also provided at the 9 Aces Golf Academy, by professional trainers. Driving range and putting green areas offer fun practice facilities. </p>
                                </div>
                            </div>
                            <div className="detailsinfo-con">
                                <h4 className="details-cont-tabhead">Location of the Course</h4>
                                <div className="infode-box map-box">
                                    <img onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} src={mapin} alt="map" />
                                    <p className="location-map">9 Aces Golf Greens & Academy</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={3}>
                            <div className='details-side-box1'>
                                <div className='side-summary-box'>
                                    <h4>Summary</h4>
                                    <h5>Golf & Ayurveda in South India</h5>
                                    <div className='summary-rate-box'>
                                        <div className='sum-date'>
                                            <p>Selected Date</p>
                                            <p>{InstantPackagesInputs && moment(InstantPackagesInputs[0]?.date, 'DD-MM-YYYY').format("Do MMM YYYY")} </p>
                                            {/* <p>{InstantPackagesInputs[0]?.date}</p> */}
                                        </div>
                                        <div className='sum-person'>
                                            <p>No of Golfers</p>
                                            <p>{getGolferCount()} Persons</p>
                                        </div>
                                        <div className='sum-nongolf'>
                                            <p>No of Non - Golfers</p>
                                            <p>4 Persons</p>
                                        </div>
                                        {/* <div className='sum-transfer'>
                                            <p>Transfer</p>
                                            <p>$29.00</p>
                                        </div> */}
                                        <div className='sum-hotels'>
                                            <p>Hotels</p>
                                            <p>{currencyCode} {hotelPrice}</p>
                                        </div>
                                        <div className='sum-golfcourse'>
                                            <p>Golf Courses</p>
                                            <p>{currencyCode} {coureprice}</p>
                                        </div>
                                        <div className='sum-perperson'>
                                            <p>Price Per Person</p>
                                            <p>{currencyCode} </p>
                                        </div>
                                        <div className='sum-total'>
                                            <h5>Total Price</h5>
                                            <p>{currencyCode} {getTotalPrice()}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='side-contact-fill-box'>
                                    <div className="contact-form">
                                        <h4>Contact Information</h4>
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={name}
                                                    placeholder='Enter your Name here'
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">Email Address</label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    value={email}
                                                    name="email"
                                                    placeholder='Enter your Email here'
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="phone">Phone</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <select id="countryCode" name="countryCode">
                                                        <option value="+1">+1</option>
                                                        <option value="+44">+44</option>
                                                        <option value="+91">+91</option>
                                                    </select>
                                                    <input
                                                        type="text"
                                                        id="phone"
                                                        name="phone"
                                                        value={phone}
                                                        placeholder='Enter your mobile number here'
                                                        onChange={(e) => setPhone(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="nationality">Nationality</label>
                                                <select
                                                    name="lead_nationality"
                                                    onChange={(e) => setCusnationality(e.target.value)}
                                                >
                                                    <option value="">Select Nationality</option>
                                                    {nationalities.map((nation, index) => (
                                                        <option key={index} value={nation?.name}>{nation?.name}</option>
                                                    ))}
                                                </select>

                                            </div>
                                            <div className="form-group captach-grp">
                                                <div className='captacha-inbox'>
                                                    <label htmlFor="nationality">Captcha Code</label>
                                                    <input
                                                        type="text"
                                                        id="nationality"
                                                        name="nationality"
                                                        placeholder='Captcha Code here'
                                                        onChange={(e) => setCaptcha(e.target.value)}
                                                    />
                                                </div>
                                                <div className='captacha-img-box'>
                                                    <div className='cap-img'>
                                                        <LoadCanvasTemplate />
                                                    </div>
                                                    {/* <div className='cap-restart'>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <g clip-path="url(#clip0_68_1121)">
                                                                    <path d="M12 5V1L7 6L12 11V7C15.31 7 18 9.69 18 13C18 16.31 15.31 19 12 19C8.69 19 6 16.31 6 13H4C4 17.42 7.58 21 12 21C16.42 21 20 17.42 20 13C20 8.58 16.42 5 12 5Z" fill="#598B28" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_68_1121">
                                                                        <rect width="24" height="24" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className='allow-access-box'>
                                                <div class="checkbox-wrapper-13 goho-check-df">
                                                    <input id="c1-13" type="checkbox" /><label for="c1-13">By submitting information, I agree to Golftripz T&C &
                                                        Privacy Policy.</label>
                                                </div>
                                                <div className='email-allow-check'>
                                                    <p>I would like to receive marketing promotions from following communication channels:</p>
                                                    <div className='email-all-df-bx'>
                                                        <div class="checkbox-wrapper-13 goho-check-df">
                                                            <input id="c1-13" type="checkbox" onChange={(e) => IsEmailChecked()} /><label for="c1-13">Email</label>
                                                        </div>
                                                        <div class="checkbox-wrapper-13 goho-check-df">
                                                            <input id="c1-13" type="checkbox" onChange={(e) => IsMessaging()} /><label for="c1-13">Online Messaging Apps</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='enq-btn'>
                                                    <button onClick={HandleSubmit}>Enquire Now</button>
                                                </div>
                                                {/* <div className='add-cart-side'>
                                                    <button>Add To Cart</button>
                                                </div> */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default InstantPackageDetails;
