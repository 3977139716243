import PrivateRoute from "../PrivateRoute"
import B2BRegistration from "../screens/B2BRegistration"
import Booking from "../screens/Booking"
import BookLogin from "../screens/BookLogin"
import CustomerRegistration from "../screens/CustomerRegistration"

import Dashboard from "../screens/Dashboard"
import Done from "../screens/Done"
import DriverRegistration from "../screens/DriverRegistration"
import DriverTrip from "../screens/DriverTrip"
import EditTrip from "../screens/EditTrip"
import ErrorPage from "../screens/ErrorPage"
import ForgotPassword from "../screens/ForgotPassword"
import Home from "../screens/Home"
import GolfHolidayNew from "../screens/GolfHolidayNew"
// import MegaMenuDefault from "../screens/Menunew"
import PaymentRefund from "../screens/PaymentRefund"
import SearchResult from "../screens/SearchResult"
import CourseDetailsPage from "../screens/CourseDetailsPage" 
import Services from "../screens/Services"
import SuccessPage from "../screens/SuccessPage"
import PassangerDetailsPage from "../screens/PassangerDetailsPage"
import TermsConditions from "../screens/TermsConditions"
import PrivacyPolicy from "../screens/PrivacyPolicy"
import BookingForm from "../screens/Home/BookingForm"
import Login from "../screens/Login"
import Pages from "../screens/pages"
import MarkUp from "../screens/MarkUp"
import PassengerDetails from "../screens/PassengerDetails"
import Payment from "../screens/Payment"
import Profile from "../screens/Profile"
import ResetPassword from "../screens/ResetPassword"
import Role from "../screens/Role"
import AddRole from "../screens/Role/AddRole"
import Summary from "../screens/Summary"
import TopUp from "../screens/TopUp"
import User from "../screens/User"
import Wallet from '../screens/Wallet'
import GolfHolidayDetailsPage from "../screens/GolfHolidayDetailsPage"
import GolfHoliday from "../screens/GolfHoliday"
import GolfEvents from "../screens/GolfEvents"
import GolfExperiences from "../screens/GolfExperiences"
import GolfEventsDetailsPage from "../screens/GolfEventsDetailPage"
import GolfExperiencesDetailsPage from "../screens/GolfExperiencesDetailsPage"
import Myaccount from "../screens/Myacc"
import CartPage from "../screens/CartPage"
import Voucher from "../screens/Voucher"
import GolfPayment from "../screens/GolfPayment"
import InstantPackages from "../screens/InstantPackages"
import InstantDetailsPage from "../screens/InstantDetailsPage"
import HomeNew from "../screens/Home"
import GolfPaymentSuccess from "../screens/GolfPaymentSuccess"


export const guest = [
    {
        path: '/',
        element: <HomeNew />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    // {
    //     path: '/',
    //     element: <MegaMenuDefault />,
    //     exact: true,
    //     errorElement: <ErrorPage />,
    // },
    {
        path: '/SearchResult',
        element: <SearchResult />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/GolfPayment',
        element: <GolfPayment/>,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/Home',
        element: <HomeNew/>,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/CourseDetailsPage',
        element: <CourseDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/GolfHoliday',
        element: <GolfHoliday />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/InstantPackages',
        element: <InstantPackages/>,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/GolfHolidayDetailsPage',
        element: <GolfHolidayDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/:slug',
        element: <Pages />
    },
    {
        path: '/GolfEvents',
        element: <GolfEvents />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/GolfEventsDetailsPage',
        element: <GolfEventsDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/GolfExperiences',
        element: <GolfExperiences />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/GolfExperiencesDetailsPage',
        element: <GolfExperiencesDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/InstantDetailsPage',
        element: <InstantDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/Myacc',
        element: <Myaccount/>,
        exact: true,
        errorElement: <ErrorPage />,
    },
   {
        path: '/payment-cancellations',
        element: <PaymentRefund />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/services',
        element: <Services />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/termsconditions',
        element: <TermsConditions />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/booking',
        element: <BookingForm />,
        exact: true
    },
    {
        path: '/signin',
        element: <Login />,
        exact: true
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassword />,
        exact: true
    },
    {
        path: '/resetpassword/:token',
        element: <ResetPassword />,
        exact: true
    },
    {
        path: '/summary',
        element: <Summary head="ADD BOOKING" />,
        exact: true
    },
    {
        path: '/passengerdetails',
        element: <PassengerDetails head="ADD BOOKING" />,
        exact: true
    },
    {
        path: '/newbooking',
        element: <Booking head="ADD BOOKING" />,
        exact: true
    },
    
    {
        path: '/loginregister',
        element: <BookLogin head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/payment',
        element: <Payment head="ADD BOOKING" />,
        exact: true
    },
    {
        path: '/GolfPayment',
        element: <GolfPayment head="Golf Payment" />,
        exact: true
    },
    {
        path: '/done',
        element: <Done head="ADD BOOKING" />,
        exact: true
    },
    {
        path: '/home',
        element: <Home head="ADD BOOKING" />,
        exact: true
    },
    {
        path: '/customerregistration',
        element: <CustomerRegistration  />,
        exact: true
    },
    {
        path: '/SuccessPage',
        element: <SuccessPage/>,
        exact: true
    },
    {
        path: '/CartPage',
        element: <CartPage/>,
        exact: true
    },
    {
        path: '/Voucher',
        element: <Voucher/>,
        exact: true
    },
    {
        path: '/PassangerDetailsPage',
        element: <PassangerDetailsPage/>,
        exact: true
    },
    {
        path: '/b2bregistration',
        element: <B2BRegistration  />,
        exact: true
    },
    {
        path: '/driverregistration',
        element: <DriverRegistration  />,
        exact: true
    },
    {
        path: '/trip',
        element: <DriverTrip  />,
        exact: true
    },
    {
        path: '/GolfHolidayNew',
        element: <GolfHolidayNew/>,
        exact: true
    },
    {
        path: '/GolfPaymentSuccess',
        element: <GolfPaymentSuccess/>,
        exact: true
    },
];

export const agent =[
    {
        path: '/',
        element: <HomeNew />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/:slug',
        element: <Pages />
    },
    {
        path: '/GolfEvents',
        element: <GolfEvents />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    // {
    //     path: '/',
    //     element: <MegaMenuDefault />,
    //     exact: true,
    //     errorElement: <ErrorPage />,
    // },
    {
        path: '/InstantPackages',
        element: <InstantPackages/>,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/InstantDetailsPage',
        element: <InstantDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/CourseDetailsPage',
        element: <CourseDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/PassangerDetailsPage',
        element: <PassangerDetailsPage/>,
        exact: true
    },
    {
        path: '/GolfHolidayDetailsPage',
        element: <GolfHolidayDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/booking',
        element: <BookingForm />,
        exact: true
    },
    {
        path: '/signin',
        element: <Login />,
        exact: true
    },
    {
        path: '/SearchResult',
        element: <SearchResult />,
        exact: true
    },
    {
        path: '/Myacc',
        element: <Myaccount/>,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassword />,
        exact: true
    },
    {
        path: '/resetpassword/:token',
        element: <ResetPassword />,
        exact: true
    },
    {
        path: '/dashboard',
        element: <PrivateRoute> <Dashboard head="MY BOOKINGS" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/topup',
        element: <PrivateRoute> <TopUp head="TOP UP"/> </PrivateRoute>,
        exact: true
    },
    {
        path: '/summary',
        element: <Summary head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/edittrip/:id',
        element: <PrivateRoute> <EditTrip head="EDIT TRIP" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/wallet',
        element: <PrivateRoute><Wallet head="MY WALLET"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/addrole',
        element: <PrivateRoute><AddRole head="ROLE MANAGEMENT"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/profile',
        element: <PrivateRoute><Profile head="PROFILE"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/markup',
        element: <PrivateRoute><MarkUp head="MARK UP MANAGEMENT" /></PrivateRoute>,
        exact: true
    },
    {
        path: '/user',
        element: <PrivateRoute><User head="USER MANAGEMENT" /></PrivateRoute>,
        exact: true
    },
    {
        path: '/role',
        element: <PrivateRoute><Role head="ROLE MANAGEMENT" /></PrivateRoute>,
        exact: true
    },
    {
        path: '/passengerdetails',
        element: <PassengerDetails head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/newbooking',
        element: <Booking head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/payment',
        element: <Payment head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/GolfPayment',
        element: <GolfPayment head="Golf Payment" />,
        exact: true
    },
    {
        path: '/SuccessPage',
        element: <SuccessPage/>,
        exact: true
    },
    {
        path: '/done',
        element: <Done head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/home',
        element: <Home />,
        exact: true
    },
    {
        path: '/customerregistration',
        element: <CustomerRegistration  />,
        exact: true
    },
    {
        path: '/b2bregistration',
        element: <B2BRegistration  />,
        exact: true
    },
    {
        path: '/driverregistration',
        element: <DriverRegistration  />,
        exact: true
    },
    {
        path: '/CartPage',
        element: <CartPage/>,
        exact: true
    },
    {
        path: '/GolfPaymentSuccess',
        element: <GolfPaymentSuccess/>,
        exact: true
    },
  
];

export const customer =[
    {
        path: '/',
        element: <HomeNew />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    // {
    //     path: '/',
    //     element: <MegaMenuDefault />,
    //     exact: true,
    //     errorElement: <ErrorPage />,
    // },
    {
        path: '/GolfEvents',
        element: <GolfEvents />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/InstantPackages',
        element: <InstantPackages/>,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/InstantDetailsPage',
        element: <InstantDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/Myacc',
        element: <Myaccount/>,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/GolfHolidayDetailsPage',
        element: <GolfHolidayDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/:slug',
        element: <Pages />
    },
    {
        path: '/PassangerDetailsPage',
        element: <PassangerDetailsPage/>,
        exact: true
    },
    {
        path: '/GolfHoliday',
        element: <GolfHoliday />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/SearchResult',
        element: <SearchResult />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/CourseDetailsPage',
        element: <CourseDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/booking',
        element: <BookingForm />,
        exact: true
    },
    {
        path: '/signin',
        element: <Login />,
        exact: true
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassword />,
        exact: true
    },
    {
        path: '/resetpassword/:token',
        element: <ResetPassword />,
        exact: true
    },
    {
        path: '/dashboard',
        element: <PrivateRoute> <Dashboard head="MY BOOKINGS" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/summary',
        element: <Summary head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/edittrip/:id',
        element: <PrivateRoute> <EditTrip head="EDIT TRIP" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/profile',
        element: <PrivateRoute><Profile head="PROFILE"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/passengerdetails',
        element: <PassengerDetails head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/newbooking',
        element: <Booking head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/payment',
        element: <Payment head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/GolfPayment',
        element: <GolfPayment head="Golf Payment" />,
        exact: true
    },
    {
        path: '/SuccessPage',
        element: <SuccessPage/>,
        exact: true
    },
    {
        path: '/done',
        element: <Done head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/home',
        element: <Home />,
        exact: true
    },
    {
        path: '/customerregistration',
        element: <CustomerRegistration  />,
        exact: true
    },
    {
        path: '/b2bregistration',
        element: <B2BRegistration  />,
        exact: true
    },
    {
        path: '/driverregistration',
        element: <DriverRegistration  />,
        exact: true
    },
    {
        path: '/CartPage',
        element: <CartPage/>,
        exact: true
    },
    {
        path: '/GolfPaymentSuccess',
        element: <GolfPaymentSuccess/>,
        exact: true
    },
];

export const corporate =[
    {
        path: '/',
        element: <HomeNew />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    // {
    //     path: '/',
    //     element: <MegaMenuDefault />,
    //     exact: true,
    //     errorElement: <ErrorPage />,
    // },
    {
        path: '/GolfEvents',
        element: <GolfEvents />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/InstantPackages',
        element: <InstantPackages/>,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/InstantDetailsPage',
        element: <InstantDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/:slug',
        element: <Pages />
    },
    {
        path: '/PassangerDetailsPage',
        element: <PassangerDetailsPage/>,
        exact: true
    },
    {
        path: '/GolfHolidayDetailsPage',
        element: <GolfHolidayDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/booking',
        element: <BookingForm />,
        exact: true
    },
    {
        path: '/Myacc',
        element: <Myaccount/>,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/signin',
        element: <Login />,
        exact: true
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassword />,
        exact: true
    },
    {
        path: '/resetpassword/:token',
        element: <ResetPassword />,
        exact: true
    },
    {
        path: '/dashboard',
        element: <PrivateRoute> <Dashboard head="MY BOOKINGS" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/summary',
        element: <Summary head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/edittrip/:id',
        element: <PrivateRoute> <EditTrip head="EDIT TRIP" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/addrole',
        element: <PrivateRoute><AddRole head="ROLE MANAGEMENT"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/profile',
        element: <PrivateRoute><Profile head="PROFILE"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/user',
        element: <PrivateRoute><User head="USER MANAGEMENT" /></PrivateRoute>,
        exact: true
    },
    {
        path: '/role',
        element: <PrivateRoute><Role head="ROLE MANAGEMENT" /></PrivateRoute>,
        exact: true
    },
    {
        path: '/passengerdetails',
        element: <PassengerDetails head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/CourseDetailsPage',
        element: <CourseDetailsPage />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/newbooking',
        element: <Booking head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/payment',
        element: <Payment head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/GolfPayment',
        element: <GolfPayment head="Golf Payment" />,
        exact: true
    },
    {
        path: '/SuccessPage',
        element: <SuccessPage/>,
        exact: true
    },
    {
        path: '/done',
        element: <Done head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/home',
        element: <Home />,
        exact: true
    },
    {
        path: '/SearchResult',
        element: <SearchResult />,
        exact: true
    },
    {
        path: '/customerregistration',
        element: <CustomerRegistration  />,
        exact: true
    },
    {
        path: '/b2bregistration',
        element: <B2BRegistration  />,
        exact: true
    },
    {
        path: '/driverregistration',
        element: <DriverRegistration  />,
        exact: true
    },
    {
        path: '/CartPage',
        element: <CartPage/>,
        exact: true
    },
    {
        path: '/GolfHolidayNew',
        element: <GolfHolidayNew/>,
        exact: true
    },
    {
        path: '/GolfPaymentSuccess',
        element: <GolfPaymentSuccess/>,
        exact: true
    },
]; 