import {
    NEW_VIP_BOOKING_SUCCESS,
    NEW_VIP_BOOKING_FAIL,
    NEW_POINT_BOOKING_SUCCESS,
    NEW_POINT_BOOKING_FAIL,
    RESET_ERROR,
    VEHICLE_AVAILABILITY_SUCCESS,
    GOLFCOURSE_AVAILABILITY_SUCCESS,
    GOLFHOLIDAY_AVAILABILITY_SUCCESS,
    GOLFHOLIDAY_BOOKING,
    GOLFHOLIDAY_BOOKING_NOT_LOGIN,
    VEHICLE_AVAILABILITY_FAIL,
    GOLFCOURSE_AVAILABILITY_FAIL,
    INSTANT_PACKAGES_SUCCESS,
    INSTANT_PACKAGES_INPUT,
    INSTANTPACKAGE_BOOKING,
    BOOKING_DATA,
    SELECTED_VEHICLE,
    SELECTED_COURSE,
    SELECTED_PACKAGES,
    GOLFCOURSE_BOOKING,
    FOOTER_MENUS,
    CART_COUNT,
    COURSE_BOOKING_ORDERS,
    ORDER_DATA,
    NEW_BOOKING_SUCCESS,
    RESET_BOOKING,
    COUPON_VERIFY_SUCCESS,
    COUPON_VERIFY_FAIL,
    CREDIT_TERMS_SUCCESS,
    CREDIT_TERMS_FAIL,
    ADD_BOOKING,
    REMOVE_ORDER_ITEM,
    PASSENGERS_DETAILS,
    RESET_ORDER_DATA,
    SET_SELECTED_BOOKING,
    SET_SELECTED_PACKAGE,
    NEW_BOOKING_FAIL,
    BOOKING_TYPE,
    RESET_BOOKING_DATA,
    SELECTED_GOLF_HOLIDAY,
    BOOKING_INPUT
} from "../constants/bookingConstants";

export const bookingReducer = (state = {}, action) => {
    switch (action.type) {

        case NEW_VIP_BOOKING_SUCCESS:
            return {
                ...state,
                newVipBooking: true
            }

        case NEW_VIP_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case NEW_POINT_BOOKING_SUCCESS:
            return {
                ...state,
                newBooking: true
            }

        case NEW_POINT_BOOKING_FAIL:
        case CREDIT_TERMS_FAIL:
        case NEW_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case VEHICLE_AVAILABILITY_SUCCESS:
            return {
                ...state,
                availableVehicles: action.payload,
                enablegett: false
            }
            
        case GOLFCOURSE_AVAILABILITY_SUCCESS:
                return {
                    ...state,
                    availableCourses: action.payload,
                    enablegett: false
                }
        case INSTANT_PACKAGES_SUCCESS:
                return {
                    ...state,
                    golfInstantPackages: action.payload,
                    enablegett: false
                }
        case INSTANT_PACKAGES_INPUT:
                return {
                    ...state,
                    InstantPackagesInputs: action.payload
                }
        case GOLFHOLIDAY_AVAILABILITY_SUCCESS:
                return {
                    ...state,
                    availableHolidays: action.payload,
                    enablegett: false
                }
        case FOOTER_MENUS:
                return {
                    ...state,
                    footerMenus: action.payload,
                    enablegett: false
                }
       
        case CART_COUNT:
                return {
                    ...state,
                    cartCount: action.payload,
                    enablegett: false
                }
       
        case VEHICLE_AVAILABILITY_FAIL:
            return {
                ...state,
                error: action.payload,
                availableVehicles: null,
                enablegett: true
            }
        case GOLFCOURSE_AVAILABILITY_FAIL:
            return {
                ...state,
                error: action.payload,
                availableCourses: null,
                enablegett: true
            }
        case COURSE_BOOKING_ORDERS:
                return {
                    ...state,
                    courseBookingInputs: action.payload,
                    enablegett: false
                }
        case GOLFCOURSE_BOOKING:
                return {
                    ...state,
                    coureBookingSuccess: true,
                    coursebookingresponce: action.payload
                }
        case FOOTER_MENUS:
                return {
                    ...state,
                    selectedCourse: action.payload
                }
        case BOOKING_DATA:
            return {
                ...state,
                bookingData: action.payload,
                bookingChanged: true
            }

        case ORDER_DATA:
            return {
                ...state,
                orderData: state?.orderData ? [...state.orderData, action.payload] : [action.payload]
            }

        case SELECTED_VEHICLE:
            return {
                ...state,
                selectedVehicle: action.payload
            }
        case SELECTED_COURSE:
            return {
                ...state,
                selectedCourse: action.payload
            }
        case SELECTED_PACKAGES:
            return {
                ...state,
                selectedPackage: action.payload
            }
        case INSTANTPACKAGE_BOOKING:
                return {
                    ...state,
                    instantBookingSuccess: true,
                    instantbookingresponce: action.payload
        }
        case SELECTED_GOLF_HOLIDAY:
            return {
                ...state,
                selectedGolfHoliday: action.payload
            }
            case GOLFHOLIDAY_BOOKING:
                return {
                    ...state,
                    holidayBookingSuccess: true,
                    holidaybookingresponce: action.payload,
                    holidaybookingnotlogin: null
                }
            case GOLFHOLIDAY_BOOKING_NOT_LOGIN:
                return {
                    ...state,
                    holidaybookingnotlogin: action.payload
                }

        case RESET_ERROR:
            return {
                ...state,
                error: null,
                newBooking: null,
                bookingChanged: null,
                newVipBooking: null,
                bookingSuccess: null,
                coureBookingSuccess: null,
                couponError: null,
                holidayBookingSuccess: null
            }

        case NEW_BOOKING_SUCCESS:
            return {
                ...state,
                bookingSuccess: true,
                bookingResponse: action?.payload
            }

        case PASSENGERS_DETAILS:
            return {
                ...state,
                passengers: action.payload
            }

        case RESET_BOOKING:
            return {
                ...state,
                bookingData: null,
                orderData: null,
                selectedVehicle: null,
                availableVehicles: null,
                couponApplied: null,
                couponDetails: null
            }

        case ADD_BOOKING:
            return {
                ...state,
                bookingData: null,
                selectedVehicle: null,
                availableVehicles: null,
                passengers: null,
                selectedBooking: null
            }

        case REMOVE_ORDER_ITEM:
            return {
                ...state,
                orderData: state.orderData.filter((order, index) => index !== action.payload)
            }

        case COUPON_VERIFY_SUCCESS:
            return {
                ...state,
                couponDetails: action.payload,
                couponApplied: true
            }

        case COUPON_VERIFY_FAIL:
            return {
                ...state,
                couponError: action.payload,
                couponDetails: null,
                couponApplied: false
            }

        case CREDIT_TERMS_SUCCESS:
            return {
                ...state,
                creditTerms: action.payload
            }

        case RESET_ORDER_DATA:
            return {
                ...state,
                orderData: action.payload
            }

        case SET_SELECTED_BOOKING:
            return {
                ...state,
                selectedBooking: action.payload.booking,
                currentVehicle: action.payload.selectedVehicle,
                selectedId: action.payload.id
            }
        case SET_SELECTED_PACKAGE:
            return {
                ...state,
                selectedBooking: action.payload.booking,
                currentVehicle: action.payload.selectedVehicle,
                selectedId: action.payload.id
            }

        case BOOKING_TYPE:
            return {
                ...state,
                bookingType: action.payload,
                enablegett: action.payload === "PAL" ? false : true
            }

        case RESET_BOOKING_DATA:
            return {
                ...state,
                bookingData: null,
                selectedBooking: null
            }

        case BOOKING_INPUT:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }


        default:
            return state;
    }
}