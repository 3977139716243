import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import HomeTopBar from '../../components/HomeTopBar';
import Footer from '../../components/Footer';
import ModifySearch from './ModifySearch';
import ModifySearchForHoliday from './ModifySearchForHoliday';
import ModifySearchInstant from './ModifySearchInstant';
import _ from 'lodash';

const Layout = ({ children, type }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { previousRoute } = useSelector(state => state.auth);
    const [headerdata, setHeaderdata] = useState({});
    const [urlpath, setUrlPath] = useState("");



    useEffect(() => {
        // Set header data
        setUrlPath(location.pathname)
        setHeaderdata({
            title: 'Golftripz',
            meta_desc: 'Golftripz',
            meta_keyword: 'golftripz, events, courses, holidays'
        });
    }, [previousRoute, location.pathname, dispatch]);

    return (
        <>
            <Helmet>
                <title>{headerdata.title}</title>
                <meta name="description" content={headerdata.meta_desc} />
                <meta name="keywords" content={headerdata.meta_keyword} />
            </Helmet>
            <div className="full-width-home">
                <div className="page-header">
                    <div className="header-width-pad bg-nav-black">
                        <HomeTopBar />
                    </div>
                </div>
                
                <div className="full-con-width">
                {urlpath ==="/GolfHoliday" || urlpath ==="/SearchResult" || urlpath ==="/GolfEvents" || urlpath ==="/GolfExperiences" || urlpath ==="/InstantPackages" ?
                <>
                {type === 'inspackage' ? (
                    <ModifySearchInstant />
                ) : type === 'golfHoliday' ? (
                    <ModifySearchForHoliday />
                ) : (
                    <ModifySearch />
                )}
                 </>
                    :""
                }
                    {children}
               
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Layout;
