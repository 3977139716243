import React, { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Typography} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import navlogo from '../../src/assets/images/golfnewlogo.png'
import whatsapp from '../../src/assets/images/_whatsapp_.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux'
import { RESET_USER } from '../Redux/constants/userConstants';
import {RESET_BOOKING } from '../Redux/constants/bookingConstants';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { getCurrencyFromDb, getUserCurrencyCode, getCurrencyRate ,getCountries } from '../Redux/actions/currencyAction'
import { useLocation } from 'react-router-dom';

const HomeTopBar = () => {

  const dispatch = useDispatch();
  const location = useLocation();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { cartCount } = useSelector(state => state.booking)

  const [cartcount, setCartcount] = useState(cartCount);
  useEffect(() => {
    var cartitems = JSON.parse(localStorage.getItem("cartData")) || [];
    setCartcount(cartitems?.length || 0)

  }, [cartCount, cartcount])

  useEffect(() => {
    var cartitems = JSON.parse(localStorage.getItem("cartData")) || [];
    setCartcount(cartitems?.length || 0)

  }, [])

  

  const { dbCurrencyCodes, userCurrencyCode } = useSelector(state => state.currency);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [currencies, setCurrencies] = useState([])
  const [activeMenu, setActiveMenu] = useState(null);

  useEffect(() => {
    const path = location.pathname;

    switch (path) {
      case '/GolfHoliday':
        setActiveMenu(1);
        break;
      case '/GolfHolidayDetailsPage':
        setActiveMenu(1);
        break;
      case '/SearchResult':
        setActiveMenu(2);
        break;
      case '/CourseDetailsPage':
        setActiveMenu(2);
        break;
      case '/PassangerDetailsPage':
        setActiveMenu(2);
        break;
      case '/InstantPackages':
        setActiveMenu(3);
        break;
      case '/InstantDetailsPage':
        setActiveMenu(3);
        break;
      case '/GolfEvents':
        setActiveMenu(4);
        break;
      case '/GolfExperiences':
        setActiveMenu(5);
        break;
      case '/contactus':
        setActiveMenu(6);
        break;
      case '/CartPage':
        setActiveMenu(7);
        break;
      default:
        setActiveMenu(null); 
    }
  }, [location]);


  useEffect(() => {
    const getCurrencyFromDbFunction = async () => {
      await dispatch(getCurrencyFromDb());
    };
    const getCountryFromDbFunction = async () => {
      await dispatch(getCountries());
    };
    getCurrencyFromDbFunction();
    getCountryFromDbFunction();

  }, [dispatch]);

  const getCurrencyForUserFunction = async (selectedCurrency) => {
    await dispatch(getUserCurrencyCode(selectedCurrency));
  };

  useEffect(() => {
    getCurrencyForUserFunction(selectedCurrency);

  }, [dispatch]);



  const getCurrencyRayeList = async (selectedCurrency) => {
    await dispatch(getCurrencyRate(selectedCurrency));
  };
  useEffect(() => {
    getCurrencyRayeList(selectedCurrency);

  }, [dispatch, selectedCurrency]);


  useEffect(() => {
    if (dbCurrencyCodes && dbCurrencyCodes.length > 0) {
      setCurrencies(dbCurrencyCodes);
    }
    if (userCurrencyCode) {
      setSelectedCurrency(userCurrencyCode)
    }
  }, [dbCurrencyCodes, userCurrencyCode]);

  const [isOpen, setIsOpen] = useState(false);

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
    getCurrencyForUserFunction(currency);
    setIsOpen(false);
  };


  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { isAuthenticated, previousRoute, user } = useSelector(state => state.auth)
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const Home = () => {
    setAnchorElNav(null);
    navigate('/home')
  };
  const AboutUs = () => {
    setAnchorElNav(null);
    navigate('/aboutus')
  };
  const CartPageDetails = () => {
    setAnchorElNav(null);
    navigate('/CartPage')
  };
  const Voucher = () => {
    setAnchorElNav(null);
    navigate('/Voucher')
  };
  const HomeNew = () => {
    setAnchorElNav(null);
    navigate('/')
  };
  const Done = () => {
    setAnchorElNav(null);
    navigate('/Done')
  };
  const Services = () => {
    setAnchorElNav(null);
    navigate('/services')
  };
  const ContactUs = () => {
    setAnchorElNav(null);
    navigate('/contactus')
  };
  const InstantPackages = () => {
    setAnchorElNav(null);
    navigate('/InstantPackages')
  };
  const handleNavMenu1 = () => {
    setAnchorElNav(null);
    navigate('/dashboard')
  };
  const handleNavMenu2 = () => {
    setAnchorElNav(null);
    navigate('/wallet')
  };
  const handleNavMenu3 = () => {
    setAnchorElNav(null);
    navigate('/topup')
  };
  const handleNavMenu4 = () => {
    setAnchorElNav(null);
    navigate('/profile')
  };
  const handleNavMenu5 = () => {
    setAnchorElNav(null);
    navigate('/markup')
  };
  const handleNavMenu6 = () => {
    setAnchorElNav(null);
    navigate('/user')
  };
  const handleNavMenu7 = () => {
    setAnchorElNav(null);
    navigate('/role')
  };
  const handleNavMenu8 = async () => {
    setAnchorElNav(null);
    dispatch({
      type: RESET_USER
    })
    await localStorage.removeItem("user")
    navigate('/home')
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const CustomerRegister = () => {
    setAnchorElNav(null);
    navigate('/customerregistration')
  };

  const CompanyRegister = () => {
    setAnchorElNav(null);
    navigate('/b2bregistration')
  };

  const SearchResult = () => {
    setAnchorElNav(null);
    navigate('/SearchResult')
  };
  const GolfHoliday = () => {
    setAnchorElNav(null);
    navigate('/GolfHoliday')
  };
  const GolfEvents = () => {
    setAnchorElNav(null);
    navigate('/GolfEvents')
  };
  const GolfExperiences = () => {
    setAnchorElNav(null);
    navigate('/GolfExperiences')
  };
  const Myaccount = () => {
    setAnchorElNav(null);
    navigate('/Myacc')
  };
  const GolfHolidayNew = () => {
    setAnchorElNav(null);
    navigate('/GolfHolidayNew')
  };


  const resetdata = () => {
    dispatch({
      type: RESET_BOOKING,
    })


  }




  const logout = async () => {
    dispatch({
      type: RESET_USER
    })
    await localStorage.removeItem("user")
    window.location.href = '/signin';
    // navigate('/signin')
    // BrowserRouter.push("/signin");
  }

  return (

    <AppBar position="static" color="default" className="header-navbar">
      <div className='header-section'>
        <div className='full-con-width'>
          <div class="header-section-list">
            <div onClick={HomeNew} class="logo">
              <img src={navlogo} alt="logo" />
            </div>
            <div class="log-reg-section">
              <div className='nav-dis' maxWidth="100%" height="97" width="100%" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Toolbar disableGutters >
                  {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <img src={Logo} style={{ width: 153, height: 50 }} onClick={resetdata} />
          </Typography> */}
                  <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} style={{ justifyContent: '' }}>
                    <div className='top-comp-menu'>
                      <div class="log-reg-btn">
                        <button onClick={() => navigate("/signin")}>Login/Signup</button>
                      </div>
                      {/* <div className='cart-sec-topbar' onClick={CartPageDetails}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <g clip-path="url(#clip0_435_2355)">
                            <path d="M7 18C5.9 18 5.01 18.9 5.01 20C5.01 21.1 5.9 22 7 22C8.1 22 9 21.1 9 20C9 18.9 8.1 18 7 18ZM1 3C1 3.55 1.45 4 2 4H3L6.6 11.59L5.25 14.03C4.52 15.37 5.48 17 7 17H18C18.55 17 19 16.55 19 16C19 15.45 18.55 15 18 15H7L8.1 13H15.55C16.3 13 16.96 12.59 17.3 11.97L20.88 5.48C21.25 4.82 20.77 4 20.01 4H5.21L4.54 2.57C4.38 2.22 4.02 2 3.64 2H2C1.45 2 1 2.45 1 3V3ZM17 18C15.9 18 15.01 18.9 15.01 20C15.01 21.1 15.9 22 17 22C18.1 22 19 21.1 19 20C19 18.9 18.1 18 17 18Z" fill="#323232" />
                          </g>
                          <defs>
                            <clipPath id="clip0_435_2355">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div> */}
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                        className='menu-icon-top'
                      >
                        <MenuIcon className='menuicon' />
                      </IconButton>
                    </div>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      keepMounted
                      className="menu-fullwidth"
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={{
                        display: { xs: 'block', md: 'none' },
                      }}
                    >
                      <div class="log-reg-section">
                        <div class="whatsapp-icon">
                          <img src={whatsapp} alt="icon" />
                        </div>
                        <p class="phone-icon"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                          <g clip-path="url(#clip0_1029_11208)">
                            <path d="M6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.68 14.91 16.08 14.82 16.43 14.94C17.55 15.31 18.76 15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z" fill="#684B2A" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1029_11208">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg></p>
                        <ul className='remove-list-style'>
                          <div className="header-currency">
                            <div className="cur-sel-item" onClick={() => setIsOpen(!isOpen)}>
                              <li value={selectedCurrency}>{selectedCurrency}</li>
                              <div className="down-icon-top">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                                    <path d="M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z" fill="#684B2A" />
                                  </svg>
                                </span>
                              </div>
                            </div>
                            {isOpen && (
                              <ul className="dropdown-menu">
                                {currencies.map((currency) => (
                                  <li value={currency?.currencyCode} onClick={() => handleCurrencyChange(currency?.currencyCode)}>
                                    {currency.currencyCode}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </ul>
                      </div>
                      <span onClick={GolfHoliday}>{activeMenu === 1 ? <Typography className='active'>Golf Holidays</Typography> : <Typography className='inactive'>Golf Holidays</Typography>}</span>

                      <span onClick={SearchResult}>{activeMenu === 2 ? <Typography className='active'>Golf Courses</Typography> : <Typography className='inactive'>Golf Courses</Typography>}</span>


                      <span onClick={GolfEvents}>{activeMenu === 4 ? <Typography className='active'>Events</Typography> : <Typography className='inactive'>Events</Typography>}</span>
                      <span onClick={GolfExperiences}>{activeMenu === 5 ? <Typography className='active'>Experiences</Typography> : <Typography className='inactive'>Experiences</Typography>}</span>
                      <span onClick={ContactUs}>{activeMenu === 6 ? <Typography className='active'>Contact Us</Typography> : <Typography className='inactive'>Contact Us</Typography>}</span>
                      <span onClick={InstantPackages} >{activeMenu === 3 ? <Typography className='active instant-nav'>Instant Package</Typography> : <Typography className='inactive instant-nav'>Instant Package</Typography>}</span>
                      <span onClick={CartPageDetails}>{activeMenu === 7 ? <Typography className='active'><FontAwesomeIcon icon={faCartShopping} /><span>{cartcount}</span></Typography> : <Typography className='inactive'><FontAwesomeIcon icon={faCartShopping} /><span>{cartcount}</span></Typography>}</span>
                      {/* <span onClick={Myaccount}>{activeMenu == '7' ? <Typography className='active'>Myaccount</Typography> : <Typography className='inactive'>Myaccount</Typography>}</span> */}
                    </Menu>
                  </Box>
                  {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            <img src={bluelogo} style={{ width: 120, height: 50 }} onClick={resetdata} />
          </Typography> */}
                  <Box className="navbar-menu" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <span onClick={GolfHoliday}>{activeMenu == '1' ? <Typography className='active'>Golf Holidays</Typography> : <Typography className='inactive'>Golf Holidays</Typography>}</span>
                    <span onClick={SearchResult}>{activeMenu == '2' ? <Typography className='active'>Golf Courses</Typography> : <Typography className='inactive'>Golf Courses</Typography>}</span>
                    <span onClick={GolfEvents}>{activeMenu == '4' ? <Typography className='active'>Events</Typography> : <Typography className='inactive'>Events</Typography>}</span>
                    <span onClick={GolfExperiences}>{activeMenu == '5' ? <Typography className='active'>Experiences</Typography> : <Typography className='inactive'>Experiences</Typography>}</span>
                    <span onClick={ContactUs}>{activeMenu == '6' ? <Typography className='active'>Contact Us</Typography> : <Typography className='inactive'>Contact Us</Typography>}</span>
                    <span onClick={InstantPackages} className='instant-pac-pad'>{activeMenu == '3' ? <Typography className='active instant-nav'>Instant Package</Typography> : <Typography className='inactive instant-nav'>Instant Package</Typography>}</span>
                    {/* <span onClick={CartPageDetails}>{activeMenu == '7' ? <Typography className='active'><FontAwesomeIcon icon={faCartShopping} /><span>{cartcount}</span></Typography> : <Typography className='inactive'><FontAwesomeIcon icon={faCartShopping} /><span className='cart-count'>{cartcount}</span></Typography>}</span> */}
                    {/* <span onClick={Myaccount}>{activeMenu == '7' ? <Typography className='active'>Myaccount</Typography> : <Typography className='inactive'>Myaccount</Typography>}</span> */}
                    {/* <span onClick={GolfHolidayNew}>{activeMenu == '7' ? <Typography className='active'>Voucher</Typography> : <Typography className='inactive'>GolfHolidayNew</Typography>}</span> */}
                    {/* <span onClick={CartPageDetails}>{activeMenu == '7' ? <Typography className='active'><FontAwesomeIcon icon={faCartShopping} /><span>{cartcount}</span></Typography> : <Typography className='inactive'><FontAwesomeIcon icon={faCartShopping} /><span>{cartcount}</span></Typography>}</span> */}
                    {/* <span onClick={Done}>{activeMenu == '7' ? <Typography className='active'>Voucher2</Typography> : <Typography className='inactive'>Voucher2</Typography>}</span> */}
                    {/* <span onClick={CartPageDetails}>{activeMenu == '7' ? <Typography className='active'>Voucher2</Typography> : <Typography className='inactive'><FontAwesomeIcon icon={faCartShopping} /></Typography>}</span> */}
                  </Box>
                  {/* <box className="user-navbarmenu" >

            <span className='loginmenu' style={{}} onClick={() => navigate("/signin")}>
              <Person sx={{ display: { xs: 'flex', md: 'flex' }, mr: 0 }} style={{}} /> Login
            </span>

            <span className='registermenu' style={{}}>
                    <RegisterMenu menuname={"Register"}/>
                </span>

            <FacebookRoundedIcon sx={{ display: { xs: 'flex', md: 'flex' }, mr: 0 }} style={{}}/>
                <Divider orientation="vertical" color='white' variant="middle" flexItem/>
                <img src={instaLogo} style={{ width: 25, height: 25,marginRight:50 }} />  
          </box> */}
                </Toolbar>
              </div>
              <div class="log-reg-btn">
                {/* {!user && <button onClick={() => navigate("/signin")}>Login/Signup</button>}
                        {isAuthenticated && <button onClick={logout}>Logout</button>} */}
                <div className="navbar">
                  {!isAuthenticated && (
                    <button onClick={() => navigate("/signin")}>Login/Signup</button>
                  )}
                  {isAuthenticated && (
                    <div className="dropdown">
                      <button onClick={toggleDropdown}>{user?.name} !</button>
                      {isDropdownOpen && (
                        <div className="dropdown-menu">
                          <a onClick={() => navigate("/myacc")}>My Profile</a>
                          <a onClick={() => navigate("/myacc")}>My Booking</a>
                          <a onClick={logout}>Logout</a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <span className='cartcircle-head' onClick={CartPageDetails}>{activeMenu == '7' ? <Typography className='active'><FontAwesomeIcon icon={faCartShopping} /><span>{cartcount}</span></Typography> : <Typography className='inactive'><FontAwesomeIcon icon={faCartShopping} /><span className='cartcount-header'>{cartcount}</span></Typography>}</span>
              <ul className='remove-list-style'>
                <div className="header-currency">
                  <div className="cur-sel-item" onClick={() => setIsOpen(!isOpen)}>
                    <li value={selectedCurrency}>
                      {selectedCurrency}
                    </li>
                    <div className="down-icon-top">
                      <span>
                        <FontAwesomeIcon icon={faChevronDown} />
                      </span>
                    </div>
                  </div>
                  {isOpen && (
                    <ul className="dropdown-menu">
                      {currencies.map((currency) => (
                        <li value={currency?.currencyCode} onClick={() => handleCurrencyChange(currency?.currencyCode)}>
                          {currency?.currencyCode}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </AppBar>
  );
};
export default HomeTopBar;
