import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container, IconButton} from '@mui/material'
import { faArrowRight,faStar,faLessThan,faGreaterThan} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import rectangle1 from '../../assets/images/Rectangle 18.png'
import rectangle2 from '../../assets/images/Rectangle 19.png'
import rectangle3 from '../../assets/images/Rectangle 20.png'
import rectangle4 from '../../assets/images/Rectangle 21.png'
import filterlftimg from '../../assets/images/ntest1.png'
import FilterListIcon from '@mui/icons-material/FilterList';
import './search.scss'

const GolfHolidaysNew = () => {

  const [selectedOption, setSelectedOption] = useState('Popular');
  const { availableCourses, bookingData } = useSelector(state => state.booking)
  const [golfCourses, setGolfCourses] = useState([]);
  const [searchdata, setSearchdata] = useState("");
  const [minimumPrice, setMinimumPrice] = useState(0);
  const [maximumPrice, setMaximumPrice] = useState(0);
  const [filteredminimumPrice, setFilMinimumPrice] = useState(0);
  const [filteredmaximumPrice, setFilMaximumPrice] = useState(0);
  const [countries, setCountries] = useState([]);
  const [upcommingdates, setUpcommingDates] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [tabdetails, setTabDetails] = useState([]);
  // const [tempTabData, setTempTabData] = useState([]);
  const [gridCourses, setGridCourses] = useState([]);
  const navigation = useNavigate();
  const [golfContents, setGolfcontents] = useState([]);
  const handleSortChange = (event) => {
    setSelectedOption(event.target.value);
  };
  
  useEffect(() => {

    setSearchdata(localStorage.getItem("search"));
    if (availableCourses !== undefined) {
      setGolfCourses(availableCourses)

      let minPrice = Math.min(...availableCourses.map(item => item.rate_sheets.start_from_cost))
      let maxPrice = Math.max(...availableCourses.map(item => item.rate_sheets.start_from_cost))
      // setMinimumPrice(minPrice)
      setMaximumPrice(maxPrice + 10)
      setFilMaximumPrice(maxPrice)

      // getUpcommingDays();
      fetchByTabCourses();
    }

  }, [availableCourses])
  
  const fetchByTabCourses = () => {
    if (availableCourses !== undefined) {
      var course_tab = [];
      var tabdata = [{
        tab: "All",
        courses: availableCourses
      }];
      // alert(tabdata[0]?.courses)
      // filterCountries(tabdata[0]?.courses);
      availableCourses.map(course => {
        course_tab.push(course?.golf_courses?.course_type_name);
      });
      if (course_tab) {
        course_tab = course_tab.filter((value, index) => course_tab.indexOf(value) === index);

        // course_tab?.map(tabs => {
        //   var arr = getTabCoures(tabs)
        //   arr.map((val) => {
        //     tabdata.push({
        //       tab: tabs,
        //       courses: arr[0]
        //     })
        //   })

        // })
        // console.log("tabdata",tabdata)
        setMainData(tabdata)
        setTabDetails(tabdata)

        // setTempTabData(tabdata)
        // setGridCourses(tabdata)
      }

    }
  }
  const packages = [
    {
      image: rectangle1,
      title: 'Golf & Ayurveda in South India',
      location: 'South India',
      days: '12 Nights | 4 Rounds',
      price: 894.95,
      rating: 4.8,
      describtion: "Play on quality golf courses in Bengaluru & end your trip with an Ayurvedic retreat in Kovalam, Kerala. Play on quality golf courses in Bengaluru & end your trip with an Ayurvedic retreat in Kovalam, Kerala.",
    },
    {
      image: rectangle2,
      title: 'Golf & Ayurveda in North Indiaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
      location: 'North India',
      days: '10 Nights | 3 Rounds',
      price: 799.95,
      rating: 4.5,
      describtion: "Play on quality golf courses in Bengaluru & end your trip with an Ayurvedic retreat in Kovalam, Kerala.",
    },
    {
      image: rectangle3,
      title: 'Golf & Ayurveda in North India',
      location: 'North India',
      days: '10 Nights | 3 Rounds',
      price: 799.95,
      rating: 4.5,
      describtion: 'nithish',
    },
    {
      image: rectangle4,
      title: 'Golf & Ayurveda in North India',
      location: 'North India',
      days: '10 Nights | 3 Rounds',
      price: 799.95,
      rating: 4.5,
    },
  ];


  const [location, setLocation] = useState('Asia');
  const [theme, setTheme] = useState('all');
  const [duration, setDuration] = useState(7);
  const [flightOption, setFlightOption] = useState('with');
  const [price, setPrice] = useState(5000);
  const [hotelCategories, setHotelCategories] = useState([]);
  const [userRatings, setUserRatings] = useState([]);
  const [packageType, setPackageType] = useState([]);
  const [userRating, setUserRating] = useState('');

  const handleReset = () => {
    setLocation('Asia');
    setTheme('all');
    setDuration(7);
    setFlightOption('with');
    setPrice(5000);
    setHotelCategories([]);
    setUserRatings([]);
    setPackageType([]);
  };

  const togglePackageType = (type) => {
    setPackageType((prevTypes) =>
      prevTypes.includes(type)
        ? prevTypes.filter((t) => t !== type)
        : [...prevTypes, type]
    );
  };

  const toggleCategory = (category) => {
    setHotelCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
  };
  const handleFlightOptionClick = (option) => {
    setFlightOption(option);
  };


  const [filterVisible, setFilterVisible] = useState(false);

  const handleFilterToggle = () => {
    setFilterVisible(!filterVisible);
  };



  return (
    <div className='listitem'>
      <div className='listitems-sec'>
        <p className='nlistpg-path'>Home <span><FontAwesomeIcon icon={faGreaterThan} /></span> GolfHoliday <span><FontAwesomeIcon icon={faGreaterThan} /></span></p>
        <Grid container spacing={2} className='nlistitem-df'>
          <Grid item xs={2.8} className={`nfilter-box ${filterVisible ? 'visible' : ''}`}>
            <div className="nfilter-container">
              <div className="nfilter-header">
                <h3>Select Filter</h3>
                <button className="nreset-button" onClick={handleReset}>
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 18" fill="none">
                    <path d="M11.5294 17.4705C10.3686 17.4705 9.28235 17.2548 8.27059 16.8234C7.25882 16.392 6.36863 15.7999 5.6 15.0469L6.94118 13.7058C7.53725 14.2862 8.22745 14.745 9.01176 15.0822C9.79608 15.4195 10.6353 15.5881 11.5294 15.5881C13.349 15.5881 14.902 14.945 16.1882 13.6587C17.4745 12.3724 18.1176 10.8195 18.1176 8.99989C18.1176 7.18028 17.4745 5.62734 16.1882 4.34106C14.902 3.05479 13.349 2.41165 11.5294 2.41165C9.7098 2.41165 8.15686 3.05479 6.87059 4.34106C5.58431 5.62734 4.94118 7.18028 4.94118 8.99989V9.16459L6.65882 7.44694L8 8.76459L4 12.7646L0 8.76459L1.34118 7.44694L3.05882 9.18812V8.99989C3.05882 7.82341 3.28235 6.72145 3.72941 5.694C4.17647 4.66655 4.78039 3.77243 5.54118 3.01165C6.30196 2.25087 7.19608 1.64694 8.22353 1.19989C9.25098 0.752826 10.3529 0.529297 11.5294 0.529297C12.7059 0.529297 13.8078 0.752826 14.8353 1.19989C15.8627 1.64694 16.7569 2.25087 17.5176 3.01165C18.2784 3.77243 18.8824 4.66655 19.3294 5.694C19.7765 6.72145 20 7.82341 20 8.99989C20 11.3528 19.1765 13.3528 17.5294 14.9999C15.8824 16.6469 13.8824 17.4705 11.5294 17.4705Z" fill="#00ACBF" />
                  </svg></span>Reset All
                </button>
              </div>
              <div className='nfilter-content'>
                {/* Location Filter */}
                <div className="nfilter-section location-fill">
                  <h4>Location</h4>
                  <select id="location" value={location} onChange={(e) => setLocation(e.target.value)}>
                    <option>Asia</option>
                    <option>Europe</option>
                    <option>America</option>
                  </select>
                  <span className='nfill-arrow'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                    <path d="M1 1L7 7L13 1" stroke="black" stroke-width="2" />
                  </svg></span>
                </div>

                {/* Themes Filter */}
                <div className="nfilter-section">
                  <h4>Themes</h4>
                  {['all', 'events', 'nightlife', 'beach', 'international'].map((option) => (
                    <label key={option}>
                      <input
                        type="radio"
                        name="theme"
                        value={option}
                        checked={theme === option}
                        onChange={(e) => setTheme(e.target.value)}
                      />
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </label>
                  ))}
                </div>

                {/* Duration Filter */}
                <div className="nfilter-section">
                  <h4>Duration</h4>
                  <input
                    type="range"
                    min="1"
                    max="14"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                  />
                  <div className="nduration-values">
                    <span className='range-default'>2N</span>
                    <span className='range-changevalue'>{duration}N</span>
                  </div>
                </div>

                {/* Flights Filter */}
                <div className="nfilter-section">
                  <h4>Flights</h4>
                  <div className='nfill-flightcombo'>
                  {['with', 'without'].map((option) => (
          <p
            key={option}
            className={`nflight-option ${flightOption === option ? 'nactive' : ''}`}
            onClick={() => handleFlightOptionClick(option)}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)} Flight
          </p>
        ))}
                  </div>
                </div>

                {/* Price Filter */}
                <div className="nfilter-section">
                  <h4>Price</h4>
                  <input
                    type="range"
                    min="0"
                    max="10000"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  <div className="nprice-values">
                    <span className='range-default'>0</span>
                    <span className='range-changevalue'>{price}</span>
                  </div>
                </div>

                {/* Hotel Category Filter */}
                <div className="nfilter-section">
                  <h4>Hotel Category</h4>
                  <div className='nfill-starcombo'>
                    {[3, 4, 5].map((star) => (
                      <p
                        key={star}
                        className={`nhotel-category ${hotelCategories.includes(star) ? 'nactive' : ''}`}
                        onClick={() => toggleCategory(star)}
                      >
                        {star} <span className='nfill-starrate'><FontAwesomeIcon icon={faStar} /></span>
                      </p>
                    ))}
                  </div>
                </div>

                {/* User Ratings Filter */}
                <div className="nfilter-section">
                  <h4>User Ratings</h4>
                  {['excellent', 'very-good', 'good', 'no-rating'].map((rating) => (
          <label key={rating}>
            <input
              type="radio"
              name="userRating" // Ensure all radio buttons share the same name for grouping
              value={rating}
              checked={userRating === rating} // Compare with single value state
              onChange={(e) => setUserRating(e.target.value)} // Update state with selected value
            />
            {rating.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
          </label>
        ))}
                </div>

                {/* Package Type Filter */}
                <div className="nfilter-section">
                  <h4>Package Type</h4>
                  <div className="nfill-packagetype">
        {['custom', 'group'].map((type) => (
          <p
            key={type}
            className={`npackage-option ${packageType.includes(type) ? 'nactive' : ''}`}
            onClick={() => togglePackageType(type)}
          >
            {type.charAt(0).toUpperCase() + type.slice(1)} Package
          </p>
        ))}
      </div>

                </div>
              </div>
              <div className='filterlftimg'>
                <img src={filterlftimg} alt="image" />
              </div>
            </div>
          </Grid>
          <Grid item xs={8.8} className='ncontent-box'>
            <div className="package-list">
              <div className='list-nheader'>
                <h2>Golf Holiday Packages</h2>
                <div className="sort-menu">
                  <div className="dropdown">
                    <label htmlFor="sortOptions" className="dropdown-label">Sort By:</label>
                    <select id="sortOptions" className="dropdown-select" value={selectedOption} onChange={handleSortChange}>
                      <option value="Popular">Popular</option>
                      <option value="A to Z">A to Z</option>
                      <option value="Z to A">Z to A</option>
                    </select>
                    <span className='narrow'><svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 14 8" fill="none">
                      <path d="M12.9188 0.178711H6.68877H1.07877C0.118772 0.178711 -0.361228 1.33871 0.318772 2.01871L5.49877 7.19871C6.32877 8.02871 7.67877 8.02871 8.50877 7.19871L10.4788 5.22871L13.6888 2.01871C14.3588 1.33871 13.8788 0.178711 12.9188 0.178711Z" fill="black" />
                    </svg></span>
                  </div>
                </div>
                <div>
                  <IconButton
                    onClick={handleFilterToggle}
                    className="filter-icon-button"
                    aria-label="Toggle filter"
                  >
                    <FilterListIcon />
                  </IconButton>
                </div>
              </div>
              <div className="package-grid">
                {packages.map((pkg, index) => (
                  <Grid item xs={5.8} key={index} className="package-card">
                    <div className="card-image">
                      <img src={pkg.image} alt={pkg.title} />
                      <span className="featured">Featured</span>
                    </div>
                    <div className="card-content">
                      <div className='card-confirst'>
                        <h3>{pkg.title}</h3>
                        <p className="nrating">★ {pkg.rating}</p>
                      </div>
                      <div className="location-and-rating">
                        <p className="days"><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"><path d="M12.0001 4.65002C7.22008 4.65002 3.33008 8.54002 3.33008 13.32C3.33008 18.1 7.22008 22 12.0001 22C16.7801 22 20.6701 18.11 20.6701 13.33C20.6701 8.55002 16.7801 4.65002 12.0001 4.65002ZM12.7501 13C12.7501 13.41 12.4101 13.75 12.0001 13.75C11.5901 13.75 11.2501 13.41 11.2501 13V8.00002C11.2501 7.59002 11.5901 7.25002 12.0001 7.25002C12.4101 7.25002 12.7501 7.59002 12.7501 8.00002V13Z" fill="#292D32"></path><path d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z" fill="#292D32"></path></svg></span> {pkg.days}</p>
                        <p className="location"><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                          <path d="M7.62906 3.56969C7.80845 3.47184 7.99906 3.62237 7.99906 3.8267V17.3825C7.99906 17.6058 7.84665 17.7946 7.64926 17.8988C7.64249 17.9024 7.63576 17.906 7.62906 17.9097L5.27906 19.2497C3.63906 20.1897 2.28906 19.4097 2.28906 17.5097V7.77969C2.28906 7.14969 2.73906 6.36969 3.29906 6.04969L7.62906 3.56969Z" fill="#00ACBF" />
                          <path d="M14.7219 6.10241C14.8922 6.18676 15 6.3604 15 6.55047V19.7036C15 20.0721 14.615 20.314 14.283 20.1541L10.033 18.1065C9.85998 18.0231 9.75 17.8481 9.75 17.656V4.44571C9.75 4.07486 10.1396 3.83306 10.4719 3.99765L14.7219 6.10241Z" fill="#00ACBF" />
                          <path d="M22 6.49006V16.2201C22 16.8501 21.55 17.6301 20.99 17.9501L17.4986 19.951C17.1653 20.1421 16.75 19.9014 16.75 19.5172V6.33038C16.75 6.15087 16.8462 5.98513 17.0021 5.89615L19.01 4.75006C20.65 3.81006 22 4.59006 22 6.49006Z" fill="#00ACBF" />
                        </svg></span>{pkg.location}</p>
                      </div>
                      <div className='pkg-describ'>
                        <p>{pkg.describtion}</p>
                      </div>
                      <div className="card-footer">
                        <div className='card-foot-para'>
                          <p className="price">${pkg.price}</p>
                          <p className='coming-de'>Onwards</p>
                        </div>
                        <button className="gradient-btn">Book Now <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></button>
                      </div>
                    </div>
                  </Grid>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div >
  )
}

export default GolfHolidaysNew;
