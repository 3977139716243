import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
    IconButton, Modal, Box, Badge
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTimes ,faDownload ,faChevronUp ,faChevronDown ,faUser, faEdit} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
const HolidayBookingList = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)

    const { holidayLists } = useSelector(state => state.trip)
    const [holidayopen, setHolidayOpen] = useState(false);
    const [selectedHolidayRow, setSelectedHolidayRow] = useState(null);
    const [holidayItineryDay, setHolidayItineryDay] = useState([]);



    const getFormattedPhone = (number) => {
        const phoneNumberObj = parsePhoneNumberFromString(`+${number}`);
        
        if (phoneNumberObj) {
            const countryCode = phoneNumberObj.countryCallingCode;
            const mobileNumber = phoneNumberObj.nationalNumber;
            return `+${countryCode} ${mobileNumber}`;
        } else {
            return number;
        }
    };

    const getBackgroundColor = (status) => {
        switch (status) {
            case 'Pending':
                return '#684B2A';
            case 'Paid':
                return '#598B28';
            default:
                return '#000000';
        }
    };

    const handleCloseHoliday = () => {
        setHolidayOpen(false);
        setSelectedHolidayRow(null);
    };

    const handleOpenHoliday = (row) => {
        setSelectedHolidayRow(row);
        setHolidayOpen(true);
        const days = [];
        row.selected_course.map((course) => {
            if (!days.includes(course.day))
                days.push(course.day)
        })
        row.selected_hotel.map((hotel) => {
            if (!days.includes(hotel.day))
                days.push(hotel.day)
        })
        row.selected_service.map((service) => {
            if (!days.includes(service.day))
                days.push(service.day)
        })
        row.selected_transfer.map((transfer) => {
            if (!days.includes(transfer.day))
                days.push(transfer.day)
        })
        days.sort((a, b) => Number(a) - Number(b));
        setHolidayItineryDay(days)
    };
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const DownloadPdf = (url) => {
      
        var link = document.createElement('a');
                link.href = url;
                link.target = '_blank'
                link.setAttribute('download', 'invoice.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
    }

    return(
        <div className='mybook-con'>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                            <TableCell sx={{ color: 'white' }}>Booking ID</TableCell>
                            <TableCell sx={{ color: 'white' }}>Date</TableCell>
                            <TableCell sx={{ color: 'white' }}>Holiday Details</TableCell>
                            <TableCell sx={{ color: 'white' }}>Golfers</TableCell>
                            <TableCell sx={{ color: 'white' }}>Non Golfers</TableCell>
                            <TableCell sx={{ color: 'white' }}>Price</TableCell>
                            <TableCell sx={{ color: 'white' }}>Booked By</TableCell>
                            <TableCell sx={{ color: 'white' }}>Phone</TableCell>
                            <TableCell sx={{ color: 'white' }}>Payment Status</TableCell>
                            <TableCell sx={{ color: 'white' }}>Booking Status</TableCell>
                            {/* <TableCell sx={{ color: 'white' }}>Payment Status</TableCell>
                            <TableCell sx={{ color: 'white' }}>Booking Status</TableCell> */}
                            <TableCell sx={{ color: 'white' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {holidayLists?.length > 0 && holidayLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }} className='book-tr-td'
                            >
                                <TableCell className='book-td-grey'>{row?.booking_ref_number}</TableCell>
                                <TableCell>{moment(row?.date).format("DD-MM-YYYY")}</TableCell>
                                <TableCell className='book-td-black'>{row?.golf_holiday_name}</TableCell>
                                <TableCell className='book-td-black'>{row?.no_of_golfers}</TableCell>
                                <TableCell className='book-td-black'>{row?.no_of_non_golfers}</TableCell>
                                <TableCell className='book-td-black'>{row?.currency} {row?.total_price.toFixed(2)}</TableCell>
                                <TableCell className='book-td-black'>{row?.customer_name}</TableCell>
                                <TableCell className='book-td-black'>{getFormattedPhone(row?.customer_phone)}</TableCell>
                                <TableCell style={{ color: getBackgroundColor(row.status), whiteSpace: 'nowrap' }}>
                                <span style={{color:"blue"}}>
                                        {row.payment_status === 1 ? (
                                            <Badge className="badge-format unconformed"  badgeContent="Pending" />
                                        ) : row.payment_status === 2 ? (
                                            <Badge className="badge-format p-conformed"  badgeContent="Partially Paid" />
                                        ) : row.payment_status === 3 ? (
                                            <Badge className="badge-format conformed"  badgeContent="Paid" />
                                        ) : (
                                            ""
                                        )}
                                    </span>

                                </TableCell>

                                <TableCell className='book-td-green' sx={{ whiteSpace: 'nowrap' }}>
                                <span style={{ color: 'primary' }}>
                                        {row.status === 1
                                            ?<Badge className='badge-format unconformed'  badgeContent="Unconfirmed">
                                           
                                          </Badge>
                                            : row.status === 2
                                                ? <Badge className='badge-format p-conformed' badgeContent="Partially Confirmed">
                                              </Badge>
                                                : row.status === 3
                                                    ? <Badge className='badge-format conformed'  badgeContent="Confirmed"></Badge>
                                                    : <Badge className='badge-format cancelled'  badgeContent="Cancelled"></Badge>}
                                    </span>

                                </TableCell>
                                <TableCell className='book-td-green' onClick={() => handleOpenHoliday(row)}><FontAwesomeIcon icon={faEye} /> {row.actions}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={holidayLists.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </Paper>
        <Modal open={holidayopen} onClose={handleCloseHoliday}>
            <div className='dashboard-popup-box'>
                <Box className="modal-box">
                    {selectedHolidayRow && (
                        <>
                            <h2 className="modal-header">Booking History</h2>
                            <button className="close-button" onClick={handleCloseHoliday}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <div className="modal-content">
                                <div className="modal-section">
                                    <p><strong>Name:</strong> {selectedHolidayRow?.customer_name || ''}</p>
                                    <p><strong>Nationality:</strong> {selectedHolidayRow?.customer_nationality || ''}</p>
                                    <p><strong>Remarks:</strong> {selectedHolidayRow?.customer_remarks || ''}</p>
                                </div>
                                <div className="modal-section">
                                    <p><strong>Mobile No:</strong> {getFormattedPhone(selectedHolidayRow?.customer_phone)}</p>
                                    <p><strong>No of Golfers:</strong> {selectedHolidayRow?.no_of_golfers || ''}</p>
                                </div>
                                <div className="modal-section">
                                    <p style={{ textTransform: 'unset' }}><strong>Email:</strong> {selectedHolidayRow?.customer_email || ''}</p>
                                    <p><strong>No of Non Golfers:</strong> {selectedHolidayRow?.no_of_non_golfers || ''}</p>
                                </div>
                            </div>
                            <div className="modal-details">
                                <h3>Booking Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>Holiday ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Holiday Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booked By</TableCell>
                                                <TableCell sx={{ color: 'white' }}>No of Golfers</TableCell>
                                                <TableCell sx={{ color: 'white' }}>No of Non Golfers</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Payment Status</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Status</TableCell>
                                                {/* <TableCell sx={{ color: 'white' }}>Payment Status</TableCell> */}
                                                {/* <TableCell sx={{ color: 'white' }}>Booking Status</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{selectedHolidayRow?.booking_ref_number || 'N/A'}</TableCell>
                                                <TableCell>{moment(selectedHolidayRow?.date).format("DD-MM-YYYY")}</TableCell>
                                                <TableCell>{selectedHolidayRow?.golf_holiday_name}</TableCell>
                                                <TableCell>{selectedHolidayRow?.customer_name}</TableCell>
                                                <TableCell>{selectedHolidayRow?.no_of_golfers}</TableCell>
                                                <TableCell>{selectedHolidayRow?.no_of_non_golfers}</TableCell>
                                                <TableCell>{selectedHolidayRow?.currency} {selectedHolidayRow?.total_price.toFixed(2)}</TableCell>
                                                <TableCell style={{ color: getBackgroundColor(selectedHolidayRow.status), whiteSpace: 'nowrap' }}>
                                                {selectedHolidayRow.payment_status === 1 ? (
                                                <Badge className="badge-format unconformed"   badgeContent="Pending" />
                                                ) : selectedHolidayRow.payment_status === 2 ? (
                                                <Badge className="badge-format p-conformed"  badgeContent="Partially Paid" />
                                                ) : selectedHolidayRow.payment_status === 3 ? (
                                                <Badge className="badge-format conformed"  badgeContent="Paid" />
                                                ) : (
                                                <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                )}
                                                </TableCell>


                                                <TableCell className='book-td-green' sx={{ whiteSpace: 'nowrap' }}>
                                                <span style={{ color: 'primary' }}>
                                                        {selectedHolidayRow.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : selectedHolidayRow.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : selectedHolidayRow.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                    </span>

                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            
                                {holidayItineryDay.length > 0 && (
                                    holidayItineryDay.map((day, index) => {
                                        // Find the corresponding items for the current day
                                        const course = selectedHolidayRow.selected_course.find(course => course.day === day);
                                        const hotel = selectedHolidayRow.selected_hotel.find(hotel => hotel.day === day);
                                        const transfer = selectedHolidayRow.selected_transfer.find(transfer => transfer.day === day);
                                        const service = selectedHolidayRow.selected_service.find(service => service.day === day);

                                        return (
                                            <div>
                                                <p className='day-btn'>Day {day}</p>
                                                <table key={index} style={{ width: '100%', borderCollapse: 'collapse' }} className='day-tablecontent'>
                                                    <thead>
                                                    <tr>
                                                        {course && (
                                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Selected Course</th>
                                                        )}
                                                        {hotel && (
                                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Selected Hotel</th>
                                                        )}
                                                        {transfer && (
                                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Selected Transfer</th>
                                                        )}
                                                        {service && (
                                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Selected Service</th>
                                                        )}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        {course && (
                                                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                       <div className='details-row-ins'>
                                                            <h5><b>{course ? course.golf_course_name : 'N/A'}</b></h5>
                                                            <p>Status:
                                                            {course.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : course.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : course.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                            </p>
                                                            <p>confirmation number:<span>{course?.booking_confirmation_number}</span></p>
                                                             <p>Description: <div dangerouslySetInnerHTML={{ __html: course ? course.description : 'N/A' }} /></p>
                                                       </div>
                                                    </td>
                                                        )}
                                                        {hotel && (
                                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                       
                                                        <div className='details-row-ins'>
                                                            <h5><b>{hotel ? hotel.hotel_name : 'N/A'}</b></h5>
                                                            <p>Status:
                                                            {hotel.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : hotel.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : hotel.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                       </p>
                                                            <p>confirmation number:<span>{hotel?.booking_confirmation_number}</span></p>
                                                             <p>Description: <div dangerouslySetInnerHTML={{ __html: hotel ? hotel.hotel_description : 'N/A' }} /></p>
                                                
                                                       </div>
                                                    </td>
                                                        )}
                                                        {transfer && (
                                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                       
                                                       <div className='details-row-ins'>
                                                            <h5><b>{transfer ? transfer.name_of_transfer : 'N/A'}</b></h5>
                                                            <p>Status:
                                                            {transfer.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : transfer.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : transfer.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                            </p>
                                                            <p>confirmation number:<span>{transfer?.booking_confirmation_number}</span></p>
                                                             <p>Description: <div dangerouslySetInnerHTML={{ __html: transfer ? transfer.description : 'N/A' }} /></p>
                                                       </div>
                                                    </td>
                                                        )}
                                                        {service && (
                                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                        
                                                        <div className='details-row-ins'>
                                                        <p>Status:
                                                        {service.status === 1 ? (
                                                            <Badge className="badge-format unconformed" badgeContent="Unconfirmed" />
                                                        ) : service.status === 2 ? (
                                                            <Badge className="badge-format p-conformed" badgeContent="Partially Confirmed" />
                                                        ) : service.status === 3 ? (
                                                            <Badge className="badge-format conformed"  badgeContent="Confirmed" />
                                                        ) : (
                                                            <Badge className="badge-format cancelled"  badgeContent="Cancelled" />
                                                        )}
                                                            </p>
                                                            <p>confirmation number:<span>{service?.booking_confirmation_number}</span></p>
                                                             <p>Description: <div dangerouslySetInnerHTML={{ __html: service ? service.service_description : 'N/A' }} /></p>
                                                        </div>
                                                    </td>
                                                        )}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        );
                                    })
                                )}
                                
                              
                                <h3>Invoice Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>S.no</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Booking Reference No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice No</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Date</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Service Type</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Customer Name</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Email</TableCell>
                                              
                                                <TableCell sx={{ color: 'white' }}>Invoice Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Total Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice</TableCell>
                                                
                                              
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedHolidayRow?.payment_details?.length >0 ?selectedHolidayRow?.payment_details?.map((invoice ,index) => (
                                                <TableRow>
                                                    <TableCell>{index +1}</TableCell>
                                                   
                                                    <TableCell>{invoice?.booking_reference_number}</TableCell>
                                                   
                                                    <TableCell>{invoice?.invoice_number}</TableCell>
                                                    <TableCell>{moment(invoice?.invoice_date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{invoice?.service_type}</TableCell>
                                                    <TableCell>{invoice?.customer_name}</TableCell>
                                                    <TableCell>{invoice?.customer_email}</TableCell>
                                                    <TableCell>{selectedHolidayRow?.currency} {invoice?.invoice_price?.toFixed(2)}</TableCell>
                                                    <TableCell>{selectedHolidayRow?.currency} {invoice?.total_price?.toFixed(2)}</TableCell>
                                                    <TableCell>
                                                    <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => DownloadPdf(invoice?.invoice_pdf)} icon={faDownload} />
                                                    {' '}
                                                    {invoice?.payment_link && invoice?.payment_link !== '' && (
                                                        <a target='_self' href={invoice?.payment_link}>Pay</a>
                                                    )}
                                                    </TableCell>
                                                   
                                                   
                                                   
                                                   
                                                </TableRow>
                                            )):
                                            <TableRow>
                                            <TableCell colSpan={10} style={{ textAlign: 'center' }}>No Invoice Informations found</TableCell>
                                        </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <h3>Transaction Details</h3>
                                <TableContainer sx={{ maxHeight: 440 }} className='popup-dash-details'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: 'green' }} className='book-tr-th'>
                                                <TableCell sx={{ color: 'white' }}>S.no</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Invoice Number</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Ref ID</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Date	</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Payment Gateway</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Transaction Price</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Payment Status</TableCell>
                                                <TableCell sx={{ color: 'white' }}>payment_remarks</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedHolidayRow?.transaction_details?.length >0 ?selectedHolidayRow?.transaction_details?.map((transaction ,index) => (
                                                <TableRow>
                                                    <TableCell>{index +1}</TableCell>
                                                    <TableCell>{transaction?.invoice_number}</TableCell>
                                                    <TableCell>{transaction?.transaction_reference_number}</TableCell>
                                                    <TableCell>{moment(transaction?.transaction_date).format("DD-MM-YYYY")}</TableCell>
                                                    <TableCell>{transaction?.payment_gateway_name}</TableCell>
                                                    <TableCell>{transaction?.transaction_price}</TableCell>
                                                    <TableCell>{transaction?.transaction_status}</TableCell>
                                                    <TableCell>{transaction?.payment_remarks}</TableCell>
                                                    <TableCell style={{cursor: "pointer"}} onClick={()=>DownloadPdf(transaction?.receipt_pdf)}> <FontAwesomeIcon icon={faDownload}  /></TableCell>
                                                </TableRow>
                                            )):
                                            <TableRow>
                                            <TableCell colSpan={9} style={{ textAlign: 'center' }}>No Transaction Information found</TableCell>
                                        </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                  <h4>Fare Details</h4>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Golfer Price</th>
                                            <td>{selectedHolidayRow?.currency} {selectedHolidayRow?.total_price_of_golfers.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th>Non Golfer Price</th>
                                            <td>{selectedHolidayRow?.currency} {selectedHolidayRow?.total_price_of_non_golfers.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th>convenience Fee</th>
                                            <td>{selectedHolidayRow?.currency} {selectedHolidayRow?.convenience_fee > 0 ? selectedHolidayRow?.convenience_fee.toFixed(2) : 0.00}</td>
                                        </tr>
                                        <tr>
                                            <th>Tax</th>
                                            <td>{selectedHolidayRow?.currency} {selectedHolidayRow?.tax > 0 ? selectedHolidayRow?.tax.toFixed(2) : 0.00}</td>
                                        </tr>
                                        <tr>
                                            <th>Total</th>
                                            <td>{selectedHolidayRow?.currency} {selectedHolidayRow?.total_price.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </Box>
            </div>
        </Modal>
    </div>
    )
}
export default HolidayBookingList