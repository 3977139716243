import { useState , useEffect } from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import { has } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt,faRoute  } from '@fortawesome/free-solid-svg-icons';
import { BOOKING_INPUT, BOOKING_TYPE, SELECTED_COURSE, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants'
import Noimage from "../../assets/images/no-img.png";

const GolfCourseList = ({courses}) => {
    const navigation = useNavigate();
    const dispatch = useDispatch()

    const {userCurrencyCode , currencyRate} = useSelector(state => state.currency);
    const [currencyCode , setCurrencyCode] = useState('')
    const [currencyRateList , setCurrencyRateList] = useState([]);
    const [courseCurrencyCode,setCourseCurrencyCode] = useState(courses?.rate_sheets?.currency?.currencyCode);

    const { bookingData } = useSelector(state => state.booking)

    useEffect(() => {
        if(userCurrencyCode){
            setCurrencyCode(userCurrencyCode)
    }
      }, [userCurrencyCode]);
    
      useEffect(() => {
        if(currencyRate){
          setCurrencyRateList(currencyRate)
    }
      }, [currencyRate]);

    const SearchDetailsPage = (slug) => {   
        navigation('/SearchDetailsPage/'+slug)
    };
    
    const Ratings = (value) => {
        let stars = [];
            for (let i = 1; i <= value; i++) {
            stars.push(
            <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
            viewBox="0 0 20 20" fill="none">
            <path
            d="M11.4421 2.92495L12.9087 5.85828C13.1087 6.26662 13.6421 6.65828 14.0921 6.73328L16.7504 7.17495C18.4504 7.45828 18.8504 8.69162 17.6254 9.90828L15.5587 11.975C15.2087 12.325 15.0171 13 15.1254 13.4833L15.7171 16.0416C16.1837 18.0666 15.1087 18.85 13.3171 17.7916L10.8254 16.3166C10.3754 16.05 9.63375 16.05 9.17541 16.3166L6.68375 17.7916C4.90041 18.85 3.81708 18.0583 4.28375 16.0416L4.87541 13.4833C4.98375 13 4.79208 12.325 4.44208 11.975L2.37541 9.90828C1.15875 8.69162 1.55041 7.45828 3.25041 7.17495L5.90875 6.73328C6.35041 6.65828 6.88375 6.26662 7.08375 5.85828L8.55041 2.92495C9.35041 1.33328 10.6504 1.33328 11.4421 2.92495Z"
            fill="#FFB800" stroke="#FFB800" strokeWidth="1.5"
            strokeLinecap="round" strokeLinejoin="round" />
            </svg></p>
        );
    }
        return stars;
    }
    
    
    const selectCurrentCourse = async () => {

        dispatch({
            type: SELECTED_COURSE,
            payload: courses
        })

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: bookingData,
                selectedCourse: courses,
                id: 'current'
            }
        })

        navigation("/CourseDetailsPage")
    }

    const fnctionConvertingPrice = (price) =>{  
        const currencyRate = currencyRateList[courseCurrencyCode];
        // alert(currencyRate)
        const convertedPrice =  price / currencyRate
        return convertedPrice.toFixed(2);
    }


    return (
      
      <Grid xs={5.7} className="li-item-box">
                                                    <div className="listitem-card">
                                                        <div className="listitem-image-sec">
                                                            <div className="listitem-img">
                                                <img src={courses?.golf_courses?.thumb_nail_images} onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} alt="image" />
                                                            </div>
                                                            <div className="img-subtag">
                                                        {courses?.golf_courses?.course_type_name && (<button>{courses?.golf_courses?.course_type_name}</button>)}
                                                                
                                                                 
                                                            </div>
                                                        </div>
                                                        <div className="listitem-bottom-con">
                                                            <div className="listitem-details">
                                                                <h4>{courses?.golf_courses?.golf_course_name}</h4>
                                                                <div className="listitem-duration">
                                                                    <p className="listitem-country-sec"><span><svg
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 24 24" fill="none">
                                                                        <path
                                                                            d="M20.6191 8.45C19.5691 3.83 15.5391 1.75 11.9991 1.75C11.9991 1.75 11.9991 1.75 11.9891 1.75C8.45912 1.75 4.41912 3.82 3.36912 8.44C2.19912 13.6 5.35912 17.97 8.21912 20.72C9.27912 21.74 10.6391 22.25 11.9991 22.25C13.3591 22.25 14.7191 21.74 15.7691 20.72C18.6291 17.97 21.7891 13.61 20.6191 8.45ZM11.9991 13.46C10.2591 13.46 8.84912 12.05 8.84912 10.31C8.84912 8.57 10.2591 7.16 11.9991 7.16C13.7391 7.16 15.1491 8.57 15.1491 10.31C15.1491 12.05 13.7391 13.46 11.9991 13.46Z"
                                                                            fill="#292D32" />
                                                                    </svg></span>{courses?.golf_courses?.state_id}, {courses?.golf_courses?.country_id}</p>

                                                                    <p><span> <FontAwesomeIcon icon={faRoute} /></span> {courses?.golf_courses?.driving_time} From Airport</p>
                                                                </div>
                                                            </div>
                                                            <div className="listitem-hotel-sec">
                                                                <div className="hotel-count">
                                                                    <p>{courses?.golf_courses?.number_of_holes} Holes</p>
                                                                </div>
                                                                <div className="listitem-stars">
                                                             
                                                                    {Ratings(courses?.golf_courses?.gt_rating)}
                                                              
                                                                </div>
                                                                {/* <div className="listitem-review-count">
                                                                    <p>({parseFloat(courses?.golf_courses?.gt_rating).toFixed(1)})</p>
                                                                </div> */}
                                                                </div>
                                                            <div className="listitem-future-list">
                                                                <ul>
                                                                   
                                                                    {courses?.golf_courses?.facilities ?
                                                                    
                                                                    courses?.golf_courses?.facilities.map(faciliti => ( 
                                                                        <li key={faciliti.id}>{faciliti.name}</li>
                                                                          )) :''}
                                                                </ul>
                                                            </div>
                                                            <div className="offer-price-sec">
                                                                <p className="item-offer"><span><svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <g clipPath="url(#clip0_2277_19082)">
                                                                        <path
                                                                            d="M17.841 9.64996L10.341 2.14996C10.041 1.84996 9.62435 1.66663 9.16602 1.66663H3.33268C2.41602 1.66663 1.66602 2.41663 1.66602 3.33329V9.16663C1.66602 9.62496 1.84935 10.0416 2.15768 10.35L9.65768 17.85C9.95768 18.15 10.3743 18.3333 10.8327 18.3333C11.291 18.3333 11.7077 18.15 12.0077 17.8416L17.841 12.0083C18.1493 11.7083 18.3327 11.2916 18.3327 10.8333C18.3327 10.375 18.141 9.94996 17.841 9.64996ZM4.58268 5.83329C3.89102 5.83329 3.33268 5.27496 3.33268 4.58329C3.33268 3.89163 3.89102 3.33329 4.58268 3.33329C5.27435 3.33329 5.83268 3.89163 5.83268 4.58329C5.83268 5.27496 5.27435 5.83329 4.58268 5.83329Z"
                                                                            fill="#598B28" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_2277_19082">
                                                                            <rect width="20" height="20" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg></span>50% Offer</p>
                                                                <p className="item-price-amt"><span>From</span>{currencyCode} {fnctionConvertingPrice(courses?.rate_sheets?.start_from_cost)}</p>
                                                            </div>
                                                            <div className="listitem-buttons">
                                                                <button className="spcl-pac-btn">Special Packages</button>
                                                                <button className="booknow-btn" onClick={selectCurrentCourse}>Book Now</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
    )
}

export default GolfCourseList