import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout';
import GolfHolidaysNew from './GolfHolidaysNew';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import {getGolfHolidayResults } from '../../Redux/actions/bookingActions'

const Index = () => {
     const [details, setDetails] = useState({'title':'Golftripz holidays','meta_desc':'Golftripz holidays','meta_keyword':'holidays , golftripz'})
    return (
        <Layout details={details}>
            <GolfHolidaysNew/>
        </Layout>
    )
}

export default Index;
